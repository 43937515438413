/* Blog */

.blog_items {
    margin-left: -30px;
    margin-right: -30px;
    font-size: 0px;
    
    @include desktop {
        margin-left: -20px;
        margin-right: -20px;
    }
    @include tablet {
        margin-left: 0px;
        margin-right: 0px;
    }
    
    .blog_item {
        display: inline-block;
        vertical-align: top;
        width: 434px;
        margin: 0 30px 60px 30px;
        font-size: 13px;
        text-align: left;
        
        @include desktop {
            margin-left: 20px;
            margin-right: 20px;
            width: 387px;
        }
        @include tablet {
            margin-left: 0px;
            margin-right: 0px;
            width: auto;
        }
    }
}

.blog_item {
	 position: relative;
	 margin: 0 0 40px 0;
     &:after {
        content: '';
        display: block;
        clear: both;
     }
    
	 &:hover {
		 .image {
			 img {
				 @include transform(scale(1.1));
			 }
			 a:before {
				 opacity: 0.1;
			 }
		 }
		 .name {
			 a {
				 color: $base_link_color;
				 text-decoration: none;
			 }
		 }
	 }
	 .image {
		 position: relative;
         width: 160px;
         float: left;
		 font-size: 0px;
		 overflow: hidden;
         
         @include mobile-m {
             width: 90px;
         }
         
		 img {
			 max-width: 100%;
			 @include transition(0.5s ease 0s);
		 }
		 a:before {
			 content: '';
			 position: absolute;
			 top: 0px;
			 left: 0px;
			 width: 100%;
			 height: 100%;
			 background: $base_white_color;
			 opacity: 0;
			 @include transition(0.5s ease 0s);
			 z-index: 2;
		 }
	 }
     .info {
         margin-left: 184px;
         
         @include mobile-m {
             margin-left: 116px;
         }
     }
	 .date {
         display: inline-block;
         vertical-align: top;
		 font-size: 14px;
		 padding: 0 15px;
         height: 28px;
         line-height: 28px;
         background: #e8e9e9;
         @include border-radius(3px);
		 margin: 0 0 14px 0;
	 }
	 .name {
		 font-size: 15px;
		 font-weight: 700;
         line-height: 19px;
         color: $base_dark_color;
		 a {
			 color: $base_dark_color;
			 text-decoration: underline;
             
             &:hover {
                 color: $base_link_color;
                 text-decoration: none;
             }
		 }
	 }
	 .short {
		 font-size: 15px;
         line-height: 19px;
		 color: $base_dark_color;
		 padding: 15px 0 0 0;
		 p {
			 margin: 0;
		 }
         
         @include mobile-m {
             display: none;
         }
	 }
}