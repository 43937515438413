/*Catalog*/

.cat_text {
  padding-top: 40px;
}

.cat_items {
  margin-left: -15px;
  margin-right: -15px;
  font-size: 0px;

  @include desktop {
    margin-left: -10px;
    margin-right: -10px;
  }
  @include tablet {
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
  }
  @include mobile {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cat_item {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    width: 290px;
    margin: 0 15px 30px 15px;
    text-align: center;

    @include desktop {
      width: 258px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include tablet {
      width: 290px;
      margin-left: 15px;
      margin-right: 15px;
    }
    @include mobile {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @media (min-width: 768px) {
    &.list {
      margin: 0;

      .cat_item {
        display: block;
        min-height: 0px;
        position: relative;
        width: auto !important;
        margin: 0 0 30px 0;

        &_i {
          position: relative;
          @include flex-row;
          width: 100%;
        }

        .image {
          min-width: 255px;
          margin: 0 20px 0 0;

          @include tablet {
            min-width: 220px;
          }
        }

        .info {
          width: 100%;
          text-align: left;
          min-height: 0px;
          padding: 20px 30px 20px 0;

          &:before {
            display: none;
          }

          @include desktop {
            width: 400px;
          }
          @include tablet {
            width: 300px;
          }
        }

        .chars {
          display: block;
        }

        .description {
          display: block;
        }

        .bottom {
          min-width: 215px;
          width: 215px;
          max-width: 100%;
          background: #eaeced;
          padding: 40px 30px;

          @include desktop {
          }
        }

        .price {
          margin-bottom: 25px;
          text-align: left;
        }

        .bts {
          padding: 0;
          background: none;

          .buy_btn {
            margin-bottom: 12px;
          }

          .click_btn {
            display: inline-block;
            margin: 0;
          }
        }

        &.disable {
          .empty_bts {
            position: relative;
          }

          .image {
            .view_bts {
              display: block;
              position: absolute;
              width: auto;
              bottom: 9px;
              left: 9px;
              right: 9px;
              top: auto;
              opacity: 0;
              visibility: hidden;

              .btn {
                background: rgba(255, 255, 255, 0.9);
                border-width: 3px;
                line-height: 34px;
              }
            }
          }

          &:hover {
            .image {
              .view_bts {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  &.brand_items {
    .cat_item {
      margin-right: 0;
      min-height: 162px;

      .image {
        a {
          height: 80px;
        }
      }

      .name {
        min-height: 1.5ex;
      }
    }
  }

  &.category_list {
    .cat_item {
      min-height: 372px;

      .image {
        @include flex-column;
        justify-content: center;
        align-items: center;
        height: 255px;
      }
    }
  }
}

.main_cat {
  position: relative;
  margin: 30px 0 20px 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  a {
    color: $base_dark_color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cat_item_i {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  height: auto;
  text-align: center;
  border: 1px solid #d7dfe7;

  .info {
    .name {
      max-height: 55px;
      min-height: 55px;
    }
  }

}

.cat_item {
  position: relative;
  min-height: 496px;
  z-index: 1;

  &:hover {
    z-index: 2;
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  &:hover {
    .cat_item_i {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }

    .image {
      a {
        &:before {
          opacity: 1;
        }
      }

      .view_btn {
        opacity: 1;
        visibility: visible;
      }
    }

    .bts {
      .buy_btn {
        background: $base_yellow2_color;
      }

      .click_btn {
        display: inline-block;
      }
    }
  }

  &.disable {
    .bar {
      .view {
        display: none;
      }
    }

    .name {
      a {
        color: #9a9a9a;

        &:hover {
          color: #9a9a9a;
        }
      }
    }

    .stock_out {
      display: block;
      margin: 15px 0 20px 0;
      color: #737373;
      font-size: 12px;

      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        @extend .sprite-icons;
        @include stitches-sprite(-20px, -597px, 10px, 10px);
        margin-right: 4px;
      }
    }

    .empty_bts {
      .btn {
        padding: 0 10px;
        display: block;
        border: 1px solid #cdcdcd;
        line-height: 38px;
        background: #fff;
        color: $base_text_color;
        font-size: 9px;

        &:hover {
          color: $base_white_color;
          background: $base_text_color;
          border-color: $base_text_color;
        }
      }
    }
  }

  &.category_item {
    .info {
      min-height: 0px;
    }

    .name {
      min-height: 2ex;
    }
  }

  .image {
    position: relative;
    font-size: 0px;
    margin: 10px 0;
    overflow: hidden;

    .image_wrapper_block {
      width: 100%;
      /*margin: 0px auto 20px;*/
      position: relative;
      padding-top: 85%;
      min-width: 60px;

      text-align: center;
      zoom: 1;
      display: block;
      left: 0;

      & > a {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 60px;
        margin: 5px auto;
        padding: 0;
        text-align: center;
        zoom: 1;
        display: block;

        .section-gallery-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 2px;
          right: 2px;
          flex-direction: row;
          z-index: 2;

          &.flexbox {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            //-ms-flex-direction: column;
            //flex-direction: column;
          }

          .section-gallery-wrapper__item {
            flex-grow: 1;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            background-color: #fff;
            background-color: var(--white_text_black_reverse);
            background: 0 0;
            margin-bottom: -10px;

            &._active {
              .section-gallery-wrapper__item-nav {
                &:before {
                  background-color: #22a81b;
                }
              }
              img {
                opacity: 1;
              }
            }

            .section-gallery-wrapper__item-nav {
              display: block;
              position: relative;
              margin: 0 2px 0 0;
              height: 100%;

              &:before {
                content: "";
                display: block;
                height: 3px;
                background-color: #cdcdcd;
                -webkit-transition: background-color .2s ease-in-out;
                transition: background-color .2s ease-in-out;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                cursor: pointer;
              }
            }

            img {
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              border: 0;
              vertical-align: middle;
              max-width: 100%;
              max-height: 100%;
              pointer-events: none;
              opacity: 0;
              transition: opacity .2s ease-in-out;
              left: 1px;
              right: 1px;
            }
          }
        }
      }
    }

    img {
      max-width: 100%;
      @include transition(0.5s ease 0s);
    }

    .hover-image {
      display: none;
    }

    &:hover {
      .have-hover {
        display: none;
      }

      .hover-image {
        display: inline;
      }
    }
  }

  .info {
    position: relative;
    padding: 15px 30px 0 30px;

    @include desktop {
      padding-left: 20px;
      padding-right: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      width: 149px;
      height: 1px;
      background: $base_border_color;
      margin-left: -74.5px;
    }
  }

  .code {
    font-size: 14px;
    margin: 0 0 6px 0;
    color: rgba(31, 38, 44, 0.7);
    min-height: 19px;
  }

  .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin: 0 0 18px 0;
    color: $base_dark_color;
    text-transform: uppercase;
    min-height: 55px;

    a {
      color: $base_dark_color;
      text-decoration: none;
      @include transition(0.2s ease 0s);

      &:hover {
        color: $base_light_color;
      }
    }
  }

  .chars {
    font-size: 15px;
    margin: 12px 0 0 0;
    display: none;

    table {
      width: 100%;

      tr:last-child {
        td {
          border-bottom: none;
        }
      }

      td {
        min-width: 72px;
        padding: 5px 10px 5px 0;
        border-bottom: 1px dotted #eaeced;

        &:last-child {
          font-weight: 700;
        }
      }
    }
  }

  .description {
    display: none;
    font-size: 12px;
    margin: 10px 0 0 0;

    p {
      margin: 0;
    }
  }

  .bottom {
    position: relative;
    z-index: 2;
  }

  .price {
    margin: 0 0 14px 0;
    min-height: 33px;

    .price_old {
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      color: rgba(31, 38, 44, 0.45);
      text-decoration: line-through;
      margin: 5px 5px 0 0;
    }

    .price_new {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      color: $base_dark_color;

      span {
        font-size: 25px;
      }
    }
  }

  .bts {
    background: #eaeced;
    text-align: center;
    padding: 12px 0 11px;

    @include tablet {
      padding: 13px 0;
    }

    .btn {
      width: 149px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      height: 37px;
      line-height: 35px;
      font-size: 13px;
      text-transform: uppercase;
      @include border-radius(3px);
    }

    .buy_btn {
      padding: 0 38px 0 0;
      border: 1px solid #8d9093;
      background: $base_white_color;
      color: $base_dark_color;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        width: 38px;
        height: 100%;
        z-index: 2;
        background: $base_yellow_color;
        @include transition(0.3s ease 0s);
      }

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -8.5px;
        width: 19px;
        height: 17px;
        background: url(../images/icon-shopping-cart.svg) no-repeat 0 0;
        background-size: contain;
        z-index: 3;
      }

      &:hover {
        background: $base_yellow2_color;
      }
    }

    .click_btn {
      display: none;
      margin-top: 7px;
      padding: 0 10px;
      border: 1px solid $base_green_color;
      background: $base_white_color;
      color: $base_green_color;

      &:hover {
        color: $base_white_color;
        background: $base_green_color;
      }
    }
  }

  .stickers {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 2;
    @include transition(0.3s ease 0s);
    text-align: left;

    .sticker {
      display: inline-block;
      vertical-align: top;
      padding: 0 7px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      color: $base_white_color;
      text-transform: uppercase;

      &.sale {
        background: $base_red_color;
      }

      &.hit {
        background: #fea100;
      }

      &.new {
        background: $base_green_color;
      }

      &.discount {
        position: absolute;
        top: 0px;
        right: 9px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #ff592d;
        @include border-radius(50%);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .view_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 172px;
    height: 37px;
    margin: -18.5px 0 0 -86px;
    @include border-radius(3px);
    border: 1px solid #d7dfe7;
    background: #e9ecef;
    line-height: 35px;
    text-align: center;
    font-size: 13px;
    color: $base_dark_color;
    visibility: hidden;
    opacity: 0;
    z-index: 2;

    //&:before {
    //  content: '';
    //  display: inline-block;
    //  vertical-align: middle;
    //  width: 17px;
    //  height: 10px;
    //  background: url(../images/icon-medical.svg) no-repeat 0 0;
    //  background-size: contain;
    //  margin-right: 8px;
    //}

    &:hover {
      background: #d7dfe7;
    }

    @include tablet {
      display: none !important;
    }
  }
}

/*Catalog Page*/

.cat_menu {
  border: 1px solid #d7dfe7;
  @include border-radius(3px);
  overflow: hidden;
  margin: 0 0 40px 0;

  & > ul {
    & > li {
      position: relative;

      &.parent {
        & > a {
          &:after {
            content: '';
            position: absolute;
            right: 14px;
            top: 50%;
            width: 8px;
            height: 4px;
            margin: -2px 0 0 0;
            background: url(../images/arr_2.svg) no-repeat 0 0;
            @include transition(0.3s ease);
          }
        }
      }

      &:hover {
        & > a {
          text-decoration: none;
        }
      }

      &.active {
        & > a {
          text-decoration: none;

          &:after {
            @include transform(scaleY(-1));
            transform-origin: 0 1px;
          }
        }

        & > ul {
          display: block;
        }
      }

      &.open {
        & > a {
          text-decoration: none;

          &:after {
            @include transform(scaleY(-1));
          }
        }

        & > ul {
          display: block;
        }
      }

      & > a {
        position: relative;
        display: block;
        color: $base_dark_color;
        text-decoration: none;
        font-size: 15px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
        background: #e9ecef;
        @include transition(0.3s ease);
        min-height: 46px;
        display: flex;
        align-items: center;
        padding: 10px 35px 10px 23px;

        &:hover {
          text-decoration: none;
        }
      }

      & > .open_lnk {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 35px;
        height: 46px;
        z-index: 2;
        cursor: pointer;
      }

      & > ul {
        display: none;
        padding: 7px 0;

        & > li {
          position: relative;

          &.parent {
            & > a {
              &:before, &:after {
                content: '';
                position: absolute;
                top: 11px;
                left: 10px;
                width: 13px;
                height: 13px;
                background: url(../images/cm_plus.png) no-repeat 0 0;
                opacity: 1;
                @include transition(0.3s ease);
              }

              &:after {
                opacity: 0;
                background: url(../images/cm_minus.png) no-repeat 0 0;
              }
            }
          }

          &:hover {
            & > a {
              background: $base_yellow_color;
              text-decoration: none;
            }
          }

          &.active {
            & > a {
              background: $base_yellow_color;
              text-decoration: none;

              &:before {
                opacity: 0;
              }

              &:after {
                opacity: 1;
              }
            }

            & > ul {
              display: block;
            }
          }

          &.open {
            & > a {
              background: $base_yellow_color;
              text-decoration: none;

              &:before {
                opacity: 0;
              }

              &:after {
                opacity: 1;
              }
            }

            & > ul {
              display: block;
            }
          }

          & > a {
            position: relative;
            display: block;
            padding: 8px 10px 8px 32px;
            font-size: 16px;
            font-weight: 400;
            color: $base_dark_color;
            text-decoration: none;
            @include transition(0.3s ease);

            &:hover {
              background: $base_yellow_color;
              text-decoration: none;
            }
          }

          & > .open_lnk {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 32px;
            height: 35px;
            z-index: 2;
            cursor: pointer;
          }

          & > ul {
            display: none;
            padding: 8px 0 0 32px;

            li {
              position: relative;
              margin: 0 0 8px 0;

              &:hover {
                & > a {
                  color: $base_link_color;
                  text-decoration: none;

                  &:before {
                    background: $base_link_color;
                  }
                }
              }

              &.parent {
                & > a, &:hover > a {
                  &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: -5px;
                    width: 13px;
                    height: 13px;
                    background: url(../images/cm_plus.png) no-repeat 0 0 !important;
                    @include border-radius(0px);
                    opacity: 1;
                    @include transition(0.3s ease);
                  }

                  &:after {
                    opacity: 0;
                    background: url(../images/cm_minus.png) no-repeat 0 0 !important;
                  }
                }
              }

              &.active {
                & > a {
                  color: $base_link_color;
                  text-decoration: none;

                  &:before {
                    background: $base_link_color;
                  }
                }

                &.parent > a {
                  &:before {
                    opacity: 0;
                  }

                  &:after {
                    opacity: 1;
                  }
                }

                & > ul {
                  display: block;
                }
              }

              &.open {
                & > a {
                  color: $base_link_color;
                  text-decoration: none;

                  &:before {
                    background: $base_link_color;
                  }
                }

                & > ul {
                  display: block;
                }
              }

              a {
                position: relative;
                display: block;
                font-size: 16px;
                padding: 0 0 0 15px;
                color: $base_text_color;
                text-decoration: none;
                @include transition(0.3s ease);

                &:before {
                  content: '';
                  position: absolute;
                  top: 7px;
                  left: 0px;
                  width: 4px;
                  height: 4px;
                  @include border-radius(50%);
                  background: $base_dark_color;
                  @include transition(0.3s ease);
                }

                &:hover {
                  color: $base_link_color;
                  text-decoration: none;

                  &:before {
                    background: $base_link_color;
                  }
                }
              }
            }

            ul {
              display: none;
              padding: 8px 0 0 15px;

              li {
                a {
                  &:before {
                    top: 9px;
                    width: 4px;
                    height: 1px;
                    @include border-radius(0%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cf_filter {
  padding: 0 0 30px 0;

  .title {
    font-size: 27px;
    margin: 0 0 20px 0;
    color: $base_dark_color;
  }

  .cf_container {
    border: 1px solid #d7dfe7;
    @include border-radius(3px);
  }

  .cf_box {
    position: relative;
    margin: 0;

    &:last-child {
      .cf_p {
        border-bottom: none;
      }
    }

    &.open {
      .cf_n {
        &:before {
          @include transform(scaleY(-1));
          top: 16px;
        }
      }

      .cf_p {
        display: block;
      }
    }

    .cf_n {
      position: relative;
      padding: 8px 45px 8px 15px;
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(31, 38, 44, 0.7);
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 1px solid #d7dfe7;

      &:hover {
        &:after {
          border-color: $base_yellow_color;
          background: $base_yellow_color;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        right: 12px;
        width: 22px;
        height: 22px;
        @include border-radius(50%);
        border: 1px solid #d7dfe7;
        background: #e9ecef;
        z-index: 1;
        @include transition(0.3s ease);
      }

      &::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 20px;
        width: 8px;
        height: 4px;
        background: url(../images/arr_2.svg) no-repeat 0 0;
        background-size: contain;
        z-index: 2;
      }
    }

    .cf_p {
      display: none;
      padding: 0;
      font-size: 16px;
      padding: 14px 15px;
      border-bottom: 1px solid #d7dfe7;

      &:after {
        content: '';
        clear: both;
        display: block;
      }
    }

    &.cf_range {
      .lt, .rt {
        position: relative;
        float: left;
        width: 50%;
        margin-bottom: 15px;
      }

      .rt {
        text-align: right;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -12px;
          width: 20px;
          height: 1px;
          background: #c8ccd1;
        }
      }

      .label {
        line-height: 31px;
        color: $base_dark_color;
        font-size: 15px;
        padding-left: 10px;
      }

      input[type="text"] {
        display: inline-block;
        vertical-align: middle;
        width: 73px;
        height: 31px;
        padding: 0 15px;
        text-align: center;
        border: 1px solid #c8ccd1;
        background: $base_white_color;
        font-size: 15px;
        font-weight: 400;
        color: $base_text_color;
        font-family: $base_font_family;
        @include border-radius(3px);
      }

      .slider_range {
        background: #dedfe0;
        border: none;
        height: 3px;
        margin: 10px 0 25px;
      }

      .ui-slider-range {
        height: 4px;
        border: none;
        background: $base_blue_color;
      }

      .ui-slider-handle {
        width: 17px;
        height: 17px;
        @include border-radius(50%);
        border: 2px solid $base_green_color;
        background: $base_white_color;
        outline: none;
        margin-top: -2px;
      }
    }

    &.cf_check {
      .jq-checkbox {
        margin-right: 10px;
      }

      label {
        display: block;
        margin: 0 0 10px 0;
        font-size: 16px;

        span {
          display: inline-block;
          max-width: 76%;
          vertical-align: top;
        }

        .info {
          color: $base_grey_color;
          max-width: 100%;
          display: inline;
        }

        mark {
          background: none;
          color: $base_grey_color;
        }
      }

      &.cf_check_half {
        label {
          display: inline-block;
          width: 49%;
        }
      }
    }

    &.cf_colors {
      .color {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 26px;
        height: 26px;
        border: 1px solid $base_border_color;
        margin: 0 14px 14px 0;

        input, .jq-checkbox {
          opacity: 0;
          position: absolute;
          left: -999px;
        }
      }
    }

    .cf_content {
      position: relative;
    }

    .readmore[data-readmore-toggle]::after {
      top: -10px;
      height: 10px;
    }

    .readmore {
      a {
        display: inline-block;
        position: relative;
        color: $base_link_color;
        text-decoration: none;
        border: none;
        background: none;
        border-bottom: 1px dotted $base_link_color;
        @include border-radius(0px);
        font-size: 13px;
        padding: 0 0 2px 0;
        height: auto;
        line-height: normal;
        text-decoration: none;

        &:hover {
          border-bottom-color: transparent;

          &:after {
            background: url(../images/icon-arrow-1.svg) no-repeat 0 0;
            background-size: contain;
          }
        }
      }

      &.open {
        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .cf_bts {
    padding: 18px 0 0 0;

    .btn {
      height: 38px;
      line-height: 36px;
    }

    .cf_btn_clear {
      color: rgba(31, 38, 44, 0.7);
      border-color: #d7dfe7;
      background: #e9ecef;
      font-size: 14px;
      text-transform: uppercase;
      padding: 0;
      width: 140px;

      &:hover {
        background: $base_dark_color;
        color: $base_white_color;
      }
    }

    .cf_btn_submit {
      float: right;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.cat_bar {
  height: 52px;
  border: 1px solid #d7dfe7;
  @include border-radius(3px);
  line-height: 50px;
  margin-bottom: 30px;
  padding: 0 0 0 7px;

  @include mobile {
    border: none;
  }

  &::after {
    content: '';
    clear: both;
    display: block;
  }

  strong {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: rgba(31, 38, 44, 0.6);
    padding: 0 18px;

    @include mobile-m {
      padding-left: 0px;
      padding-right: 5px;
      font-size: 11px;
    }
  }

  .sort {
    float: left;
    width: 50%;

    .jq-selectbox {
      width: 190px;
      vertical-align: top;
      top: -1px;
    }

    .jq-selectbox__select {
      height: 52px;
      line-height: 50px;
    }

    .jq-selectbox__dropdown {
      top: 51px !important;
    }

    @include mobile {
      width: auto;
    }
  }

  .view {
    float: right;
    font-size: 0px;

    @include mobile {
      display: none;
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      border-right: 1px solid #d7dfe7;

      &:last-child {
        border-right: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &.list::before {
        margin: -6px 0 0 -6.5px;
        width: 18px;
        height: 15px;
        background: url(../images/list.png) no-repeat 0 0;
      }

      &.list.active::before {
        background: url(../images/list_h.png) no-repeat 0 0;
      }

      &.grid::before {
        margin: -6px 0 0 -6px;
        width: 15px;
        height: 15px;
        background: url(../images/grid.png) no-repeat 0 0;
      }

      &.grid.active::before {
        background: url(../images/grid_h.png) no-repeat 0 0;
      }
    }
  }

  .limit {
    float: right;

    .jq-selectbox {
      width: 80px;
      vertical-align: top;
      top: -1px;
    }

    .jq-selectbox__select {
      height: 52px;
      line-height: 50px;
    }

    .jq-selectbox__dropdown {
      top: 51px !important;
    }

    @include mobile {
      display: none;
    }
  }
}

.scrolltop {
  display: none;
  position: fixed;
  z-index: 99;
  bottom: 40px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: $base_light_color;
  @include border-radius(3px);

  &:hover {
    &::before {
      @include stitches-sprite(-20px, -3154px, 10px, 23px);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -11.5px 0 0 -5px;
    @extend .sprite-icons;
    @include stitches-sprite(-20px, -2811px, 10px, 23px);
    @include transform(rotate(90deg));
  }
}

.left_prods {
  .title {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 700;
    color: $base_dark_color;
  }

  .item {
    margin: 0 0 15px 0;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .image {
      float: left;
      width: 60px;
      position: relative;

      img {
        max-height: 100%;
        max-width: 100%;
      }

      .num {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 23px;
        height: 23px;
        font-size: 13px;
        font-weight: 700;
        color: $base_white_color;
        background: $base_yellow_color;
        line-height: 23px;
        text-align: center;
        @include border-radius(3px);
      }
    }

    .info {
      margin-left: 78px;
    }

    .name {
      font-size: 14px;
      margin: 0 0 8px 0;

      a {
        color: $base_link_color;
        text-decoration: underline;

        &:hover {
          color: $base_link_color;
          text-decoration: none;
        }
      }
    }

    .sku {
      margin: 0 0 10px 0;
      font-size: 14px;
      color: #94a6a8;
    }

    .price {
      .price_old {
        display: inline-block;
        vertical-align: bottom;
        font-size: 14px;
        color: $base_light_color;
        text-decoration: line-through;
        margin-left: 10px;
      }

      .price_new, span {
        display: inline-block;
        vertical-align: baseline;
        font-size: 28px;
        font-weight: 700;
        color: $base_dark_color;
      }
    }
  }
}


// Filter for mobile //
@media (max-width: 767px) {
  .rt_column{
    .cat_bar {
      .sort{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .mobile_filter{
          display: inline-block!important;
          cursor: pointer;
          font-size: 14px;
          color: #1f262c;
          text-transform: initial;
        }

        .jq-selectbox__select{
          border: none;
          display: flex;
          justify-content: end;

          .jq-selectbox__select-text{
            width: 70%;
          }
        }
      }
      .sort_title{
        display: none;
      }
    }
  }

  .lt_column.openFilterMobile{
    display: block!important;

    .cat_menu,
    .left_prods,
    .lt_banner{
      display: none;
    }

    .cf_filter{
      position: fixed;
      width: 100%;
      height: calc(100% - 72px);
      z-index: 1000;
      background: #fff;
      top: 72px;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: scroll;

      .cf_hide_link {
        display: flex !important;
        padding: 15px 10px;
        justify-content: flex-end;
      }

      .cf_container{
        border-left: 0;
        border-right: 0;

        .cf_bts{
          .cf_btn{
            width: 100%;
          }
        }
      }
    }
  }
}