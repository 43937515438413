/*
    Mixins
*/

@mixin border-radius($radius){
	border-radius: $radius;
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	-khtml-border-radius: $radius;
}

@mixin box-shadow($param){
	box-shadow: $param;
	-moz-box-shadow: $param;
	-webkit-box-shadow: $param;
	-khtml-box-shadow: $param;
}

@mixin transition($param){
	 transition: $param;
	 -moz-transition: $param;
	 -webkit-transition: $param;
	 -o-transition: $param;
}

@mixin transform($param) {
	 transform: $param;
	 -webkit-transform: $param;
	 -moz-transform: $param;
	 -o-transform: $param;
}

@mixin animate($animation, $duration, $delay, $fill, $count, $easing) {
	 animation-name: $animation;
	 animation-duration: $duration;
	 animation-delay: $delay;
	 animation-fill-mode: $fill;
	 animation-iteration-count: $count;
	 animation-timing-function: $easing;
	 -webkit-animation-name: $animation;
	 -webkit-animation-duration: $duration;
	 -webkit-animation-delay: $delay;
	 -webkit-animation-fill-mode: $fill;
	 -webkit-animation-iteration-count: $count;
	 -webkit-animation-timing-function: $easing;
}

@mixin stitches-sprite($x: 0, $y: 0, $width: 0, $height: 0) {
    background-position: $x $y;
    width: $width;
    height: $height;
}

@mixin background-gradient($color1, $color2){
	 background: $color2;
	 background: -webkit-linear-gradient($color1, $color2);
	 background: -o-linear-gradient($color1, $color2);
	 background: -moz-linear-gradient($color1, $color2);
	 background: linear-gradient($color1, $color2);
}

@mixin flex-column {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

@mixin flex-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

/* Plaseholder Color */
@mixin ph($color) {
    &::-webkit-input-placeholder {color: $color;}
    &:-moz-placeholder           {color: $color;}
    &::-moz-placeholder          {color: $color;}
    &:-ms-input-placeholder      {color: $color;}
}

/* Media Query Vars */
$desktop-width: 1340px;
$tablet-width : 1199px;
$mobile-width : 767px;
$mobile-m-width : 520px;

/* Media Query Mixins */
@mixin desktop {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin mobile {
	@media screen and (max-width: #{$mobile-width}) {
   		@content;
  	}
}

@mixin mobile-m {
    @media screen and (max-width: #{$mobile-m-width}) {
        @content;
    }
}

/* Sprite */

@mixin stitches-sprite($x: 0, $y: 0, $width: 0, $height: 0) {
   /* background-image: url(../images/spritesheet.png);*/
    background-repeat: no-repeat;
    background-position: $x $y;
    width: $width;
    height: $height;
}