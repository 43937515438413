/*
* HELPERS
*/

@import "helpers/vars";
@import "helpers/mixins";

/*
* BASE
*/

@import "base/fonts";
@import "base/base";

/*
* LAYOUT
*/

@import "layout/header";
@import "layout/footer";
@import "layout/popups";

/*
* PAGES
*/

@import "pages/index";
@import "pages/blog";
@import "pages/text";
@import "pages/account";
@import "pages/albums";
@import "pages/contacts";
//@import "pages/reviews";
@import "pages/thanks";
@import "pages/404";
@import "pages/faq";
@import "pages/catalog";
@import "pages/product";
@import "pages/order";

.cat_items .cat_item .image img, .product_page .pr_images img {
  max-width: calc(100% - 2px);
}

.section-gallery-wrapper__item:last-child .section-gallery-wrapper__item-nav {
  margin: 0;
}

.product_page .product_top .pr_images .big_image a {
  display: block;
}

.product_page .product_top .pr_images .st_count {
  top: -1px;
}

.cat_items.l_grid .lt .image:before {
  content: none;
}

.items-group .image_wrapper_block, .prods_tabs .image_wrapper_block {
  margin: 0px auto 16px;

}

.prods_carousel .owl-carousel .item {
  padding: 10px 0 80px;
}

@media (max-width: 600px) {
  .section-gallery-wrapper__item-nav:before {
    content: none
  }
  .cat_item .image {
    border-bottom: 1px solid #e7e7e7;
  }
}

/*.cat_item .image {
  border:none;
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}
.cat_item .image{
  border-top: none
}*/