/*
    Account
*/

/*Login*/

.login {

}

.login_lt {
	 float: left;
	 width: 520px;
	 background: $base_bg0;
    
    @include tablet {
        width: 420px;
    }
    
    @include mobile {
        width: auto;
        float: none;
        margin: 0;
        
        &.inner {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @include mobile-m {
        .c_form {
            .bts {
                text-align: center;
            }
        }
    }
}

.login_rt {
	 margin-left: 520px;
	 ul {
		 margin: 0 0 30px 0;
	 }
	 li {
		 margin: 0 0 22px 0;
		 font-weight: 500;
         position: relative;
         padding: 0 0 0 20px;
         &:before {
             content: '';
             position: absolute;
             top: 3px;
             left: 0px;
             width: 13px;
             height: 13px;
             background: url(../images/icon-check3.svg) no-repeat 0 0;
             background-size: contain;
	    }
    }
    
    @include tablet {
        margin-left: 420px;
    }
    
    @include mobile {
        margin-left: 0px;
        &.inner {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    @include mobile-m {
        text-align: center;
    }
}

.login_form {
	 .bts {
         @include mobile-m {
             text-align: center;
         }
         
		 .btn {
			 float: left;
			 width: 196px;
             
             @include mobile-m {
                 float: none;
             }
		 }
		 .link {
			 float: left;
			 margin: 20px 0 0 30px;
			 text-decoration: none;
			 border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 border-bottom-color: transparent;
			 }
             
             @include mobile-m {
                 float: none;
                 display: inline-block;
                 vertical-align: top;
                 margin-left: 0px;
             }
		 }
	 }
    
     .check {
         @include mobile-m {
             font-size: 12px;
         }
     }
    
     .login-social {
        text-align: left;
        
        @include mobile-m {
            text-align: center;
        }
     }
}
.form_forget {
    .bts {
        .btn {
            width: 220px;
        }
    }
}

/*Account*/

.account {
	 .acc_title {
		 span {
			 &::before {
                 content: '';
                 position: relative;
                 left: 2px;
                 display: inline-block;
                 vertical-align: bottom;
                 margin: 0 10px 0 0;
                 width: 29px;
                 height: 27px;
                 background: url(../images/favorite.svg) no-repeat 0 0;
                 background-size: contain;
			 }
		 }
	 }
}

.acc_text {
	 margin: 0 0 20px 0;
	 p {
		 margin: 0 0 7px 0;
	 }
	 .wellcome {
		 font-size: 18px;
         font-weight: 700;
	 }
	 .discount {
		 strong {
             color: $base_red_color;
			 font-weight: 700;
		 }
	 }
}

.acc_bonus {
    position: relative;
    padding: 38px 0;
    background: $base_bg1;
    overflow: hidden;
    margin-bottom: 30px;
    
    @include mobile {
        padding: 30px 20px;
        text-align: center;
    }
    
    .image {
		 position: absolute;
		 top: 0px;
		 left: 38px;
		 width: 78px;
		 height: 100%;
		 &::before {
			 content: '';
			 position: absolute;
			 top: 50%;
			 left: 50%;
			 width: 78px;
             height: 74px;
             background: url(../images/icon_sales.svg) no-repeat 0 0;
             background-size: contain;
			 margin: -37px 0 0 -39px;
		 }
         
         @include tablet {
            left: 30px;    
         }
         @include mobile {
            display: none;
         }
    }
    .text {
		 float: left;
		 font-size: 18px;
		 line-height: 26px;
		 font-weight: 700;
		 margin-top: -7px;
		 margin-left: 170px;
		 text-transform: uppercase;
         color: $base_text_color;
		 span {
			 color: $base_red_color;
		 }
         
        @include tablet {
            margin-left: 135px;    
        }
        @include mobile {
            float: none;
            margin-bottom: 20px;
            margin-left: 0px;
            font-size: 14px;
        }
    }
    .bts {
		 float: right;
		 margin-right: 60px;
         
        @include tablet {
            margin-right: 20px;    
        }
        @include tablet {
            margin-right: 0px;
            float: none;
        }
    }
}

.acc_list {

}

.acc_item {
	 padding: 20px 0;
	 .acc_form {
		 max-width: 470px;
		 .bts {
			 .check {
				 line-height: 40px;
			 }
			 .btn {
				 min-width: 160px;
				 max-width: 100%;
			 }
		 }
	 }
	 .acc_form_addr {
		 display: none;
	 }
}

.order_list {
    padding: 30px 0;
    
    .responsive_table {
        overflow-x: auto;
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        font-size: 13px;
    }
    th {
        font-weight: 400;
        padding: 30px 24px;
        background: $base_blue_color;
        text-align: left;
        text-transform: uppercase;
        color: $base_white_color;
        border: 1px solid $base_blue_color;
    }
    tr:nth-child(even) {
		 td {
			 background: #f7f7f7;
		 }
    }
    tr:hover {
		 td {
			 background: #fffee2;
		 }
    }
    td {
		 padding: 20px 24px 14px 24px;
		 border-bottom: 1px solid #e8e8e8;
		 &:first-child {
			 border-left: 1px solid #e8e8e8;
		 }
		 &:last-child {
			 border-right: 1px solid #e8e8e8;
		 }
		 a {
			 color: $base_link_color;
			 text-decoration: underline;
			 &:hover {
				 text-decoration: none;
			 }
		 }
		 .red {
			 color: $base_red_color;
		 }
		 .new, .arch {
			 display: inline-block;
			 min-width: 102px;
			 height: 30px;
			 line-height: 28px;
			 text-align: center;
			 padding: 0 5px;
			 background: transparent;
			 border: 1px solid $base_blue_color;
			 color: $base_blue_color;
		 }
		 .arch {
			 background: transparent;
			 border-color: transparent;
			 color: $base_text_color;
		 }
    }
}

.order_block_info_line {
	 font-size: 15px;
	 font-weight: 700; 
}