/*Text*/

.text_box {
    font-size: 15px;
    color: $base_dark_color;
    
    &_2 {
        column-count: 2;
        column-gap: 70px;
    }
    
    .date {
		 display: inline-block;
         vertical-align: top;
		 font-size: 14px;
		 padding: 0 15px;
         height: 28px;
         line-height: 28px;
         background: #e8e9e9;
         @include border-radius(3px);
		 margin: 0 0 14px 0;
	 }
	 h1, h2, h3, h4, h5 {
         color: $base_dark_color;
	 }
	 h1 {
		 font-size: 26px;
		 line-height: 26px;
		 font-weight: 700;
		 margin: 0 0 20px 0;
         
         @include mobile {
             font-size: 20px;
         }
	 }
	 h2 {
		 font-size: 18px;
		 line-height: 18px;
		 font-weight: 700;
		 margin: 0 0 20px 0;
         text-transform: uppercase;
	 }
	 h3 {
		 font-size: 16px;
		 line-height: 16px;
		 font-weight: 700;
		 margin: 0 0 20px 0;
         text-transform: uppercase;
	 }
	 h4 {
		 font-size: 15px;
		 font-weight: 700;
		 margin: 0 0 20px 0;
	 }
	 p {
		 margin: 0 0 20px 0;
		 padding: 0;
		 line-height: 22px;
	 }
	 ul, ol {
		 margin: 0 0 20px 20px;
		 padding: 0;
		 list-style: none;
         line-height: 22px;
		 li {
			 position: relative;
			 
			 padding: 0 0 0 20px;
             & > ul, & > ol{
				 margin: 15px 0 0 0;
             }
		 }
	 }
	 ul {
         &.styled {
             margin-left: 0px;
             li {
                 padding: 0 0 0 27px;
                 margin: 0 0 10px 0;
                 &:before {
                     content: '';
                     position: absolute;
                     top: 3px;
                     let: 0px;
                     width: 13px;
                     height: 13px;
                     background: url(../images/icon-check3.svg) no-repeat 0 0;
                     background-size: contain;
                 }
             }
         }
         & > li {
            padding-left: 18px;
            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: 0px;
                width: 6px;
                height: 6px;
                background: $base_green_color;
                @include border-radius(50%);
            }
         }
	 }
	 ol {
		 & > li {
             padding-left: 38px;
			 counter-increment: item;
			 &::before {
				 position: absolute;
				 top: -3px;
				 left: 0px;
				 content: counter(item);
                 width: 24px;
                 height: 24px;
                 line-height: 24px;
                 text-align: center;
                 border: 1px solid $base_green_color;
                 @include border-radius(50%);
                 color: $base_green_color;
                 font-size: 14px;
			 }
		 }
	 }
	 img {
		 max-width: 100%;
		 overflow: hidden;
	 }
	 a {
		 color: $base_link_color;
		 text-decoration: underline;
		 &:hover {
			 text-decoration: none;
		 }
	 }
	 blockquote {
		 position: relative;
		 padding: 35px 40px 25px 50px;
		 margin: 0 0 20px 0;
		 border: 1px solid #d7dfe7;
         @include border-radius(3px);
         color: $base_text_color;
         
         small {
             display: block;
             text-align: right;
             color: rgba(31,38,44,0.7);
             font-size: 14px;
             padding: 8px 0 0 0;
             
             &:before {
                 content: '';
                 display: inline-block;
                 vertical-align: middle;
                 width: 35px;
                 height: 1px;
                 margin-right: 10px;
                 background: $base_text_color;
             }
         }
         
         @include mobile {
             padding-left: 70px;
             padding-right: 70px;
         }
         
         @include mobile-m {
             padding-left: 15px;
             padding-right: 15px;
         }
         
		 &:before {
			 content: '';
			 position: absolute;
			 top: 13px;
			 left: 13px;
			 width: 28px;
             height: 24px;
             background: url(../images/icon-q.svg) no-repeat 0 0;
			 z-index: 2;
             
             @include mobile {
                 left: 10px;
             }
             @include mobile-m {
                 display: none;
             }
		 }
	 }

	 table.styled {
		 width: 100%;
		 border-collapse: collapse;
		 margin: 20px 0;
		 padding: 0;
         border: 1px solid #d4d8db;
         
		 th {
			 font-weight: 700;
			 padding: 10px 30px;
			 background: $base_yellow_color;
			 text-align: left;
			 color: $base_dark_color;
             border: none;
             border-right: 1px solid #e8c728;
             &:last-child {
                 border-right: none;
             }
             &.num {
                 background: #e8c728;
                 max-width: 30px;
                 padding-left: 10px;
                 padding-right: 10px;
             }
		 }
		 tr:nth-child(even) {
			 td {
				 background: #e9ecef;
                 border-right-color: #d4d8db;
                 &.num {
                    background: #d4d8db;
                 }
			 }
		 }
		 tr:hover {
			 td {
				 background: #d4d8db;
                 border-right-color: #c2c6c9;
			 }
		 }
		 td {
			 padding: 10px 30px;
			 vertical-align: top;
             border: none;
			 border-right: 1px solid #e8e9e9;
             &:last-child {
                 border-right: none;
             }
             
             &.num {
                 background: #e8e9e9;
                 max-width: 30px;
                 padding-left: 10px;
                 padding-right: 10px;
             }
		 }
	 }
	 
	 table {
		 width: 100%;
		 border-collapse: collapse;
		 margin: 20px 0;
		 padding: 0;
		 border-top: 1px solid #e8e8e8;
		 border-left: 1px solid #e8e8e8;
		 th, td {
			 border-bottom: 1px solid #e8e8e8;
			 border-right: 1px solid #e8e8e8;
			 padding: 6px 8px;
		 }
         
         &.no_border {
             border-top: none;
             border-left: none;
             
             th, td {
                 border-bottom: none;
                 border-right: none;
             }
         }
	 }
	 
     .responsive_table {
        overflow-x: auto;
     }
    
    iframe, object {
        max-width: 100%;
    }
    
    @include tablet {
        table, table.styled {font-size: 12px;}
        table th, table.styled th {padding: 10px;}
	    table td, table.styled td {padding: 10px;}
    }
    @include mobile {
        table, table.styled {font-size: 11px;}
        table th, table.styled th {padding: 5px;}
	    table td, table.styled td {padding: 5px;}
    }
    
}

/*gallery*/

.gallery {
    font-size: 0px;
    padding: 20px 0;
    margin-left: -10px;
    margin-right: -10px;
    
    @include tablet {
        text-align: center;    
    }
    
	 a {
		 position: relative;
		 display: inline-block;
		 vertical-align: top;
		 width: 138px;
		 overflow: hidden;
		 margin: 0 10px 20px 10px;
		 z-index: 1;
		 img {
			 max-width: 100%;
             @include transition(0.5s ease);
		 }
		 &::before {
			 content: '';
			 position: absolute;
			 top: 50%;
			 left: 50%;
             width: 74px;
             height: 70px;
             margin: -35px 0 0 -37px;
			 background: rgba(255,255,255,0.7);
			 z-index: 2;
			 opacity: 0;
			 @include transition(0.3s ease);
		 }
		 &::after {
			 content: '';
			 position: absolute;
			 top: 50%;
			 left: 50%;
			 width: 27px;
             height: 27px;
			 margin:  -13.5px 0 0 -13.5px;
             background: url(../images/icon-zoom.svg) no-repeat 0 0;
             background-size: contain;
			 z-index: 3;
			 @include transition(opacity 0.5s ease);
			 opacity: 0;
		 }
		 &:hover{
             img {
                 opacity: 0.61;
                 @include transform(scale(1.1));
             }
			 &::before {
				 opacity: 1;
			 }
			 &::after {
				 opacity: 1;
			 }
		 }
	 }
}