/*Popup*/

.overlay {
	 display: none;
	 position: fixed;
	 top: 0px;
	 left: 0px;
	 width: 100%;
	 height: 100%;
	 background: rgba(209,209,209,0.8);
	 z-index: 101;
}

.popup_menu {
	 position: fixed;
	 top: 0px;
	 left: -300px;
	 width: 260px;
	 height: 100%;
	 z-index: 1002;
	 background: #fff;
	 ul {
		 margin: 10px 0;
		 border-top: 1px solid #e7e7e7;
		 li {
			 position: relative;
			 font-size: 15px;
			 text-transform: uppercase;
			 font-weight: 400;
			 border-bottom: 1px solid #e7e7e7;
			 a {
				 position: relative;
				 display: block;
				 padding: 12px 40px 12px 10px;
				 color: $base_text_color;
				 text-decoration: none;
				 &:hover {
					 color: $base_blue_color;
					 text-decoration: none;
					 background: #f9f9f9;
				 }
			 }
			 ul {
				 display: none;
				 border-top: none;
				 li {
					 position: relative;
					 padding: 7px 14px;
					 border-bottom: none;
					 font-size: 14px;
                     text-transform: initial;
                     
					 a {
						 padding: 0;
						 &:hover {
							 color: $base_blue_color;
							 background: none;
						 }
					 }
					 &.active > a {
						 color: $base_blue_color;
						 background: none!important;
					 }
					 li {
						 font-weight: 400;
					 }
					 &.parent {
						& > a {
							 padding-right: 20px;
							 &::after {
								 right: 0px;
							 }
						}
					 }
				 }
			 }
			 &.parent {
				& > a {
					 &::after {
						 content: '';
						 position: absolute;
						 top: 50%;
						 right: 20px;
				 		 border: 4px solid transparent;
                         border-left: 5px solid $base_text_color;
						 margin: -4px 0 0 0;
					 }
				 }
				 &.active {
					 & > a {
						 color: $base_blue_color;
						 text-decoration: none;
						 background: #f9f9f9;
						 &::after {
				            border: 4px solid transparent;
                            border-top: 5px solid $base_blue_color;
						 }
					 }
					 & > ul{
						 display: block;
					 }
				 }
			 }
			 &.active > a {
				 color: $base_blue_color;
				 text-decoration: none;
				 background: #f9f9f9;
			 }
		 }
	 }
	 	 
	 .close {
		 position: absolute;
		 top: 10px;
		 right: -40px;
		 z-index: 2;
		 width: 30px;
		 height: 30px;
		 &::before {
			 content: '';
			 position: absolute;
			 top: 13px;
			 left: 7px;
			 width: 20px;
			 height: 2px;
			 background: $base_text_color;
			 @include transform(rotate(-45deg));
		 }
		 &::after {
			 content: '';
			 position: absolute;
			 top: 13px;
			 left: 7px;
			 width: 20px;
			 height: 2px;
			 background: $base_text_color;
			 @include transform(rotate(45deg));
		 }
	 }
	 
	 .hsearch {
		 position: relative;
		 width: 90%;
		 height: 40px;
		 margin: 10px 5% 10px 5%;
		 
		 input[type="search"] {
			 position: absolute;
			 top: 0px;
			 left: 0px;
			 width: 100%;
			 height: 40px;
			 background: transparent;
			 -webkit-appearance: none;
			 border: 1px solid $base_border_color;
			 padding: 0 48px 0 20px;
			 font-family: $base_font_family;
			 color: $base_dark_color;
			 font-size: 16px;
			 z-index: 2;
		 }
		 .search_btn {
			 position: absolute;
			 top: 0px;
			 right: 8px;
			 width: 40px;
			 height: 40px;
			 cursor: pointer;
			 border: none;
			 z-index: 2;
			 &::before {
				 content: '';
				 position: absolute;
				 top: 0px;
				 left: 0px;
				 width: 100%;
                 height: 100%;
                 background: url(../images/icon-magnifying-glass.svg) no-repeat center center;
                 background-size: 60% auto;
			 }
		 }
	 }
	 
	 .contacts {
		 position: relative;
		 margin: 30px 0;
		 padding: 0 5%;
		 text-align: center;
         .tel {
			 position: relative;
			 display: inline-block;
			 vertical-align: top;
			 font-size: 25px;
			 color: $base_text_color;
			 padding: 0 0 0 30px;
             &:before {
                 content: '';
                 position: absolute;
                 top: 0px;
                 left: 0px;
                 width: 30px;
                 height: 30px;
                 background: url(../images/icon-tel.svg) no-repeat 0 center;
                 background-size: 70% auto;
             }
			 &:hover {
				 color: $base_text_color;
				 text-decoration: none;
			 }
		 }
	 }
    .bts {
         padding: 0 5%;
         .btn {
             display: block;
             margin-bottom: 15px;
         }
    }
}

.popup_menu_in {
	 position: absolute;
	 top: 0px;
	 left: 0px;
	 width: 100%;
	 height: 100%;
	 overflow: auto;
}

/*Popups*/

.nonebox {
	 position: absolute;
	 left: 50%;
	 top: -3000px;
	 width: 890px;
	 margin: 0 0 0 -445px;
	 z-index: 1002;
	 background: $base_white_color;
     @include border-radius(5px);
    
     @include tablet {
        width: 720px;
        margin-left: -360px;
     }
     @include mobile {
        width: 480px;
        margin-left: -240px;
     }
     @include mobile-m {
        width: 300px;
        margin-left: -150px;
     }
    
	 &.small {
		 top: -1000px;
		 width: 380px;
		 margin-left: -190px;
         
         @include mobile {
             width: 300px;
             margin-left: -150px;
         }
	 }
	 &.one_popup {
		 width: 780px;
		 margin-left: -390px;
         
         @include tablet {
             width: 720px;
             margin-left: -360px;
         }
         
         @include mobile {
             width: 480px;
             margin-left: -240px;
         }
         
         @include mobile-m {
             width: 300px;
             margin-left: -150px;
         }
         
         .c_item {
             margin: 0 0 45px 0;
             
             @include mobile {
                 margin-bottom: 30px;
             }
             
             &:after {
                 content: '';
                 display: block;
                 clear: both;
             }
             
             .image {
                float: left;
                width: 180px;
                position: relative;
                font-size: 0px;
                overflow: hidden;
                 
                @include mobile {
                    width: 90px;
                }
                @include mobile-m {
                    float: none;
                    width: auto;
                    margin-bottom: 20px;
                }
                 
                img {
                    max-width: 100%;
                }
             }
             .info {
                overflow: hidden;
                margin-left: 200px;
                 
                @include mobile {
                    margin-left: 110px;
                }
                @include mobile-m {
                    margin-left: 0px;
                }
             }
             .code {
                font-size: 13px;
                margin: 0 0 6px 0;
                color: rgba(31,38,44,0.7);
             }
             .name {
                font-size: 13px;
                font-weight: 700;
                line-height: 18px;
                color: $base_dark_color;
                text-transform: uppercase;
                
                @include mobile {
                    margin-bottom: 15px; 
                } 
                 
                a {
                    color: $base_dark_color;
                    text-decoration: none;
                    @include transition(0.2s ease 0s);
                    &:hover {
                        color: $base_light_color;
                    }
                }
             }
             .chars {
                font-size: 15px;
                margin: 10px 0 0 0;
                padding: 14px 0 0 0;
                border-top: 1px solid #d7dfe7;
                
                @include mobile {
                    font-size: 13px;     
                }
                 
                table {
                    width: 100%;

                    tr:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                    td {
                        min-width: 72px;
                        padding: 5px 10px 5px 0;
                        border-bottom: 1px dotted #eaeced;
                        &:last-child {
                            font-weight: 700;
                        }
                    }
                }
             }
             .price {
                 font-size: 22px;
                 font-weight: 700;
             }
             .lt {
                 float: left;
                 width: 75%;
                 padding-right: 20px;
                 
                 @include mobile {
                     float: none;
                     width: auto;
                     padding-right: 0px;
                 }
             }
             .rt {
                 float: left;
                 width: 25%;
                 height: 55px;
                 line-height: 55px;
                 padding-left: 20px;
                 border-left: 1px solid #d7dfe7;
                 
                 @include mobile {
                     float: none;
                     width: auto;
                     height: auto;
                     line-height: normal;
                     padding-left: 0px;
                     border-left: none;
                 }
             }
         }
         
         .cart_table {
             border: none;
             margin-bottom: 30px;
         }
         
		 .nonebox_inner {
             padding-top: 20px;
			 
             @include mobile {
                 padding-top: 40px;
             }
             @include mobile-m {
                 padding-left: 15px;
                 padding-right: 15px;
             }
             
			 .c_form {
                 .group {
                     float: left;
                     width: 242px;
                     margin: 0 20px 0 0;
                     
                     @include tablet {
                         width: 214px;
                     }
                     @include mobile {
                         float: none;
                         width: auto;
                         margin-right: 0px;
                         margin: 0 0 14px 0;
                     }
                 }
                 .bts {
                     float: right;
                     width: 168px;
                     margin: 20px 0 0 0;
                     
                     @include mobile {
                         float: none;
                         width: auto;
                         margin: 0;
                     }
                 }
			 }
		 }
	 }
	 &.fast_view {
		 width: 1000px;
		 margin-left: -500px;
         
         @include tablet {
             display: none !important;
         }
         
		 .nonebox_inner {
			 padding: 27px 25px 37px 25px;
		 }

		 .vprev, .vnext {
			 position: absolute;
			 left: -52px;
			 top: 50%;
			 margin-top: -26.5px;
			 width: 24px;
			 height: 53px;
			 cursor: pointer;
			 z-index: 10;
			 &::before, &::after {
				 content: '';
				 position: absolute;
				 left: 0;
				 top: 0;
				 @include stitches-sprite(-20px, -1087px, 24px, 70px);
				 @include transition(0.2s ease 0s);
				 opacity: 1;
			 }
			 &::after {
				 opacity: 0;
				 @include stitches-sprite(-20px, -1303px, 24px, 70px);
			 }
			 &:hover {
				 &::before {
					 opacity: 0;
				 }
				 &::after {
					 opacity: 1;
				 }
			 }
		 }
		 .vnext {
			 left: auto;
			 right: -52px;
			 &::before, &::after {
				 @include transform(rotate(180deg));
			 }
		 }
		 .product_top {
            padding: 0;
             
            .pr_left {
                width: 393px;
            }
            .pr_right {
                margin-left: 428px;
            }
            .b_images {
                margin: 0;
                overflow: visible;
                .owl-carousel {
                    min-height: 340px;
                    @include flex-column;
                    justify-content: center;
                }
                a {
                    display: block;
                    overflow: hidden;
                    @include border-radius(3px);
                }
            }
            .pr_labels {
                float: none;
                width: auto;
                text-align: center;
            }
            .share {
                float: none;
                width: auto;
                text-align: center;
                margin: 15px 0 0 0;
            }
            .pr_desc {
                .h_title {
                    padding: 0;
                    text-align: left;
                    font-size: 20px;
                    margin: 0 0 14px 0;
                } 
            }
             .pr_chars {
                 font-size: 15px;
                 padding: 0;
                 margin-bottom: 10px;
                 
                 h3 {
                    font-size: 17px;
                    margin: 0;
                 }
             }
             .pr_params_1 {
                 margin-bottom: 10px;
             }
             .pr_price {
                 padding: 0;
                 border: none;
                 
                 .prices {
                     width: 30%;
                     
                     .new_price {
                        line-height: 55px;
                     }
                 }
                 
                 .prices2 {
                     float: left;
                     width: 60%;
                     line-height: 55px;
                     font-size: 26px;
                     font-weight: 700;
                     padding-left: 40px;
                     border-left: 1px solid #d7dfe7;
                 }
             }
             .pr_buy {
                 padding: 13px 0 0 0;
             }
		 }
		 .readmore {
			 padding: 14px 0 0 0;
			 a {
				 text-decoration: underline;
				 &:hover {
					 text-decoration: none;
				 }
			 }
		 }

          h1.h_title {
            display: none;
          }
       .product_top .pr_desc .print_btn, .pr_params_2{
         display: none;
       }
         
         
	 }
	 &.cart_popup {
         width: 780px;
		 margin-left: -390px;
         
         @include tablet {
             width: 720px;
             margin-left: -360px;
         }
         
         @include mobile {
             width: 480px;
             margin-left: -240px;
         }
         
         @include mobile-m {
             width: 300px;
             margin-left: -150px;
         }
         
		 .nonebox_inner {
			 padding: 16px 45px 30px;
             
             @include mobile {
                 padding: 24px 10px;
             }
		 }
		 .red_alert {
			 padding: 23px 24px;
             margin-bottom: 16px;
             .icon {
                 left: 24px;
                 width: 50px;
                 height: 50px;
                 margin-top: -25px;
                 &.price {
                     &:before {
                         width: 24px;
                         height: 23px;
                         margin: -11.5px 0 0 -12px;
                     }
                 }
				 &.info:before {
					 content: '';
					 position: absolute;
					 top: 11px;
					 left: 11px;
					 width: 28px;
					 height: 28px;
				 }
             }
             .text {
                 padding-left: 78px;
                 
                 @include mobile {
                     padding-left: 0px;
                 }
                 
                 .title {
                     font-size: 18px;
                     margin: 0;
                 }
                 p {
                     font-size: 14px;
                 }
             }
             .bts {
                 width: 142px;
                 
                 @include tablet {
                     margin-left: 78px;
                 }
                 @include mobile {
                     margin-left: 0px;
                 }
                 
                 .btn {
                     min-width: 100%;
                     padding: 0 5px;
                     font-size: 15px;
                 }
             }
		 }
         .cart_table {
            margin-bottom: 25px; 
             
            @include mobile-m {
                 max-height: 475px;
                 overflow-y: auto;
                 td {
                     &.count-col {
                         padding-left: 72px;
                         width: 163px;
                     }
                     .image {
                         width: 62px;
                     }
                     .desc {
                         margin-left: 72px;
                     }
                 }
            }
         }
		 .total {
			 padding: 0 0 20px 0;
             float: right;
             width: 50%;
             text-align: right;
             
             @include mobile {
                 width: 100%;
                 text-align: right;
             }
             @include mobile-m {
                 text-align: center;
             }
             
			 .label {
                 display: inline-block;
                 vertical-align: bottom;
                 font-size: 18px;
                 color: $base_text_color;
                 line-height: 22px;
             }
			 .price {
                 display: inline-block;
                 vertical-align: bottom;
				 font-size: 28px;
                 line-height: 28px;
				 color: $base_text_color;
                 min-width: 122px;
                 padding-left: 20px;
                 
				 strong {
					 font-weight: 700;
				 }
			 }
		 }
         .back_link {
             float: left;
             margin: 0;
             
             @include mobile {
                 line-height: 40px;
             }
             @include mobile-m {
                 float: none;
                 text-align: center;
             }
         }
		 .bts {
             @include mobile-m {
                 text-align: center;
             }
             
             .order_btn {
                 float: right;
                 clear: right;
                 
                 @include mobile-m {
                    float: none;
                 }
             }
		 }
	 }
    
	 .ntitle {
		 padding: 0 40px;
		 text-align: left;
		 height: 55px;
         line-height: 54px;
		 font-size: 23px;
		 font-weight: 700;
		 color: $base_dark_color;
         background: $base_yellow_color;
		 border-bottom: 1px solid $base_dark_color;
         @include border-radius(5px 5px 0 0);
		 margin: 0;
	 }
     
	 .nonebox_inner {
		 padding: 35px 40px;
         
		 .c_form {
			 padding: 0;
             
             .group {
                 margin-bottom: 20px;
             }
             
			 .bts {
				 float: none;
				 width: 100%;
				 text-align: center;
				 margin-top: 0;
                
                 &_forget {
                     .forget_link {
                         display: inline-block;
                         vertical-align: middle;
                         border-bottom: 1px dotted $base_link_color;
                         margin-right: 15px;
                         
                         @include mobile-m {
                             margin: 0 0 15px 0;
                         }
                         
                         &:hover {
                             border-bottom-color: transparent;
                         }
                     }
                     .btn {
                         vertical-align: middle;
                     }
                 }
                 
				 .btn {
					 display: inline-block;
					 min-width: 167px;
                     font-size: 16px;
				 }
			 }
		 }
         
		 .alert_send {
			 display: none;
			 p {
				 margin: 30px 0;
				 font-size: 16px;
				 color: $base_dark_color;
				 text-align: center;
			 }
		 }
	 }
     
     .bottom {
         height: 57px;
         line-height: 57px;
         font-size: 14px;
         text-align: center;
         background: #eaeced;
         @include border-radius(0px 0px 5px 5px);

         a, span {
             color: $base_green_color;
             text-decoration: none;
             border-bottom: 1px dotted $base_green_color;
         }
     }
     
	 .close {
		 position: absolute;
		 width: 25px;
		 height: 25px;
         right: 13px;
         top: 14px;
		 cursor: pointer;
		 font-size: 0;
         @include transition(0.3s ease);
         
         &:before, &:after {
             margin-left: -2px;
             margin-top: -1.5px;
         }
		 &:before {
			 content: '';
			 position: absolute;
			 top: 13px;
			 left: 7px;
			 width: 16px;
			 height: 2px;
			 background: $base_dark_color;
			 @include transform(rotate(-45deg));
		 }
		 &:after {
			 content: '';
			 position: absolute;
			 top: 13px;
			 left: 7px;
			 width: 16px;
			 height: 2px;
			 background: $base_dark_color;
			 @include transform(rotate(45deg));
		 }
	 }
}