/* Albums */

.album_items {
    font-size: 0px;
    text-align: center;
    margin: 0 -15px;

    @include mobile-m {
        margin: 0;
    }

    &.sertificat {
        text-align: left;
        
        @include mobile-m {
            text-align: center;
        }

        .album_item {
            width: 224px;
        }
    }

    .album_item {
        display: inline-block;
        vertical-align: top;
        width: 291px;
        margin: 0 15px 30px 15px;

        @include desktop {
            width: 252px;
        }
    }
}

.album_item {
    position: relative;
    font-size: 17px;
    padding: 20px;
    background: #fbfbfc;
    text-align: left;
    border: 1px solid #e1e5e4;
    @include transition(0.3s ease 0s);
  &.category{
    .image {
      a {
        &:before {
          background: none;
        }
        &:after {
          background: none;
        }
      }
    }
  }

    &:hover {
        border-color: $base_blue_color;
        .image {
            a {
                &:before {
                    opacity: 1;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        .name {
            a {
                color: $base_blue_color;
            }
        }
    }

    .image {
        position: relative;
        font-size: 0px;
        overflow: hidden;

        img {
            max-width: 100%;
        }

        a {
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: rgba(0,37,55,0.6);
                opacity: 0;
                z-index: 2;
                @include transition(0.3s ease 0s);
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 32px;
                height: 34px;
                margin: -17px 0 0 -16px;
                background: url(../images/zoom.svg) no-repeat 0 0;
                background-size: contain;
                @include transition(0.3s ease 0s);
                opacity: 0;
                z-index: 3;
            }
        }
    }

    .name {
        margin: 16px 0 0 0;
        font-weight: 700;
        line-height: 21px;
        height: 42px;
        overflow: hidden;

        a {
            color: $base_dark_color;
            text-decoration: none;

            &:hover {
                color: $base_blue_color;
                text-decoration: none;
            }
        }
    }

    .count {
        position: relative;
        margin: 16px 0 0 0;
        font-weight: 300;
        padding: 0 0 0 30px;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            width: 20px;
            height: 16px;
            margin: -8px 0 0 0;
            background: url(../images/photo.svg) no-repeat 0 0;
            background-size: contain;
        }
    }
}