/*
    Footer
*/

.footer {
  position: relative;
  z-index: 2;
  background: #191e23;
  color: $base_white_color;
  font-size: 15px;

  .ftop {
    background: $base_dark_color;
    padding: 25px 0 20px 0;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }

  .fmiddle {
    padding: 32px 0;

    &:after {
      content: '';
      clear: both;
      display: block;
    }
  }

  .fbottom {
    font-size: 14px;

    @include tablet {
      font-size: 12px;
    }

    .fw {
      border-top: 1px solid #474b4f;
      padding: 16px 0 20px 0;

      &:after {
        content: '';
        clear: both;
        display: block;
      }
    }
  }


  .f_title {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .f_col {
    float: left;

    &.f_col_1 {
      width: 215px;

      @include tablet {
        display: none;
      }
    }

    &.f_col_2 {
      width: 320px;

      @include tablet {
        display: none;
      }
    }

    &.f_col_3 {
      width: 215px;

      @include tablet {
        display: none;
      }
    }

    &.f_col_4 {
      float: right;
      width: 425px;

      @include tablet {
        float: none;
        width: auto;
        .clr {
          display: none;
        }
      }
    }
  }

  .f_menu {
    ul {
      li {
        position: relative;
        margin-bottom: 8px;

        a {
          color: #b8b9ba;
          text-decoration: none;

          &:hover {
            color: $base_green_color;
            text-decoration: none;
          }
        }

        &.active {
          color: $base_green_color;
          text-decoration: none;
        }
      }
    }
  }

  .contacts {
    float: left;
    display: inline-block;
    vertical-align: top;
    width: 235px;
    position: relative;
    text-align: right;

    @include mobile-m {
      float: none;
      width: auto;
      margin-bottom: 10px;
      text-align: center;
    }

    a {
      display: inline-block;
      vertical-align: top;
      border-bottom: 1px dashed $base_white_color;
      text-decoration: none;

      &:hover {
        border-bottom-color: transparent;
      }
    }

    .tel, a.tel {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 26px;
      color: $base_white_color;
      padding-left: 32px;
      text-decoration: none;
      border-bottom: none;

      &:hover {
        color: $base_white_color;
        text-decoration: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        width: 19px;
        height: 19px;
        margin: -9.5px 0 0 0;
        background: url(../images/icon-tel2.svg) no-repeat 0 0;
        background-size: contain;
      }
    }

    .call_btn {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      height: 24px;
      line-height: 22px;
      padding: 0 10px;
      border: 1px solid $base_green_color;
      text-decoration: none;
      color: $base_green_color;
      @include border-radius(3px);
      margin: 4px 0 0 0;

      &:hover {
        color: $base_white_color;
        text-decoration: none;
        background: $base_green_color;
      }
    }
  }

  .time {
    float: right;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 53px;
    font-size: 14px;
    margin: 0;
    padding: 1px 0 0 24px;
    text-align: left;
    color: $base_white_color;
    max-width: 165px;

    @include tablet {
      float: left;
      margin-left: 30px;
    }
    @include mobile-m {
      float: none;
      width: auto;
      text-align: center;
      margin: 0;
      display: block;
      padding-left: 0;
      max-width: initial;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 1px;
      height: 100%;
      background: rgba(211, 211, 211, 0.2);
    }

    strong {
      font-weight: 400;
      color: #b8b9ba;
    }
  }

  .subs {
    position: relative;
    max-width: 878px;
    margin: 0 auto;

    @include mobile {
      text-align: center;
    }

    &_title {
      position: relative;
      float: left;
      width: 310px;
      padding: 0 0 0 65px;
      text-align: right;
      font-size: 16px;

      @include mobile {
        float: none;
        width: auto;
        margin: 0 0 20px 0;
        text-align: left;
        display: inline-block;
      }

      &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0px;
        width: 54px;
        height: 42px;
        background: url(../images/icon-note-1.svg) no-repeat 0 0;
        background-size: contain;
      }

      strong {
        display: block;
        font-size: 21px;
        font-weight: 700;
        color: $base_yellow_color;

        @include mobile-m {
          font-size: 18px;
        }
      }
    }

    &_form {
      position: relative;
      float: right;
      margin-bottom: 20px;
      width: 538px;

      @include tablet {
        width: 390px;
      }
      @include mobile {
        width: auto;
        float: none;
        &:after {
          content: '';
          clear: both;
          display: block;
        }
      }

      input {
        float: left;
        width: 360px;
        height: 40px;
        border: none;
        background: #ededed;
        padding: 0 20px;
        font-size: 16px;
        color: $base_dark_color;
        font-family: $base_font_family;
        @include border-radius(4px);
        outline: none;
        color: $base_dark_color;
        @include ph($base_light_color);

        @include tablet {
          width: 210px;
        }
        @include mobile {
          width: 300px;
        }
        @include mobile-m {
          width: 100%;
          margin: 0 0 15px 0;
          float: none;
        }
      }

      .btn_subs {
        float: right;
        width: 166px;
        height: 40px;
        line-height: 40px;
        background: $base_yellow_color;
        color: $base_dark_color;
        font-size: 16px;
        font-weight: 300;
        border: none;
        cursor: pointer;
        outline: none;
        @include border-radius(4px);
        @include transition(0.25s ease);
        text-align: center;
        text-decoration: none;

        @include mobile-m {
          display: block;
          float: none;
          width: 100%;
        }

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 19px;
          height: 19px;
          background: url(../images/icon-check.svg) no-repeat 0 0;
          background-size: contain;
          margin-left: 10px;
        }

        &:hover {
          background: $base_yellow2_color;
        }
      }
    }

    .info {
      font-size: 12px;
      color: #b8b9ba;
      text-align: center;
      margin: 0;

      a {
        color: #b8b9ba;
        text-decoration: none;
        border-bottom: 1px dotted #b8b9ba;

        &:hover {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .social {
    font-size: 0px;
    padding: 30px 0 0 0;
    margin: 0 0 30px 0;
    text-align: right;

    @include tablet {
      float: right;
      padding-top: 15px;
    }
    @include mobile {
      text-align: center;
      float: none;
      clear: both;
      width: auto;
      padding-top: 30px;
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 9px 9px;
      text-decoration: none;
      text-align: center;
      line-height: 34px;
      width: 36px;
      height: 36px;
      border: 1px solid #43474b;
      @include border-radius(2px);
      @include transition(0.25s ease 0s);
      background: transparent;

      &:before {
        font-family: 'social';
        font-size: 13px;
        font-weight: 300;
        font-style: normal;
        @include transition(0.25s ease 0s);
        color: $base_white_color;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.31);
        border-color: rgba(0, 0, 0, 0.31);
      }

      &.vk {
        &:before {
          content: '\f189';
        }
      }

      &.od {
        &:before {
          content: '\f263';
        }
      }

      &.fb {
        &:before {
          content: '\f09a';
        }
      }

      &.tw {
        &:before {
          content: '\f099';
        }
      }

      &.gp {
        &:before {
          content: '\f30f';
        }
      }

      &.mr {
        &:before {
          content: '\e800';
        }
      }

      &.pi {
        &:before {
          content: '\f231';
        }
      }

      &.in {
        &:before {
          content: '\f31e';
        }
      }

      &.sh {
        &:before {
          content: '\e801';
        }
      }

      &.yt {
        &:before {
          content: '\f167';
        }
      }

      &.ln {
        &:before {
          content: '\f0e1';
        }
      }
    }
  }

  .pay {
    @include border-radius(4px);
    height: 47px;
    line-height: 47px;
    background: #1f262c;
    padding: 0 10px 0 19px;

    @include mobile-m {
      text-align: center;
    }

    strong {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 400;
      color: #b8b9ba;
      padding-right: 12px;

      @include mobile-m {
        display: none;
      }
    }

    img {
      vertical-align: middle;
    }

    @include tablet {
      clear: both;
    }
  }

  a {
    color: $base_white_color;
    text-decoration: underline;

    &:hover {
      color: $base_white_color;
      text-decoration: none;
    }
  }

  .copy {
    float: left;
    width: 33.3%;

    p {
      margin: 0;
    }

    @include mobile {
      float: none;
      width: auto;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .politic {
    float: left;
    width: 33.3%;
    text-align: center;

    a {
      color: $base_white_color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include mobile {
      float: none;
      width: auto;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .create {
    float: right;
    width: 33.3%;
    text-align: right;

    p {
      margin: 0;
    }

    a {
      color: $base_green_color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include mobile {
      float: none;
      width: auto;
      text-align: center;
    }
  }
}