/*
    Fonts
*/

@font-face {
	font-family: 'DejaVu Sans';
	src: url('../fonts/DejaVuSans.eot');
	src: url('../fonts/DejaVuSans.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DejaVuSans.woff') format('woff'),
		url('../fonts/DejaVuSans.ttf') format('truetype'),
		url('../fonts/DejaVuSans.svg#DejaVuSans') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DejaVu Sans';
	src: url('../fonts/DejaVuSans-Bold.eot');
	src: url('../fonts/DejaVuSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DejaVuSans-Bold.woff') format('woff'),
		url('../fonts/DejaVuSans-Bold.ttf') format('truetype'),
		url('../fonts/DejaVuSans-Bold.svg#DejaVuSans-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
  font-family: 'social';
  src: url('../fonts/social.eot?41231882');
  src: url('../fonts/social.eot?41231882#iefix') format('embedded-opentype'),
       url('../fonts/social.woff2?41231882') format('woff2'),
       url('../fonts/social.woff?41231882') format('woff'),
       url('../fonts/social.ttf?41231882') format('truetype'),
       url('../fonts/social.svg?41231882#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'social';
    src: url('../fonts/social.svg?41231882#fontello') format('svg');
  }
}