/*404 Page*/

.error_content {
	 position: relative;
     
	 .num {
		 padding: 40px 0 0 0;
         text-align: center;
         
         img {
             max-width: 100%;
         }
	 }
     
	 .error_title {
		 margin: 20px 0 35px 0;
		 text-align: center;
         font-size: 17px;
         
		 strong {
			 display: block;
			 font-size: 31px;
			 font-weight: 400;
             line-height: 31px;
			 text-transform: uppercase;
			 margin-bottom: 8px;
             
             @include mobile-m {
                 font-size: 22px;
             }
		 }
         
         a {
			 text-decoration: underline;
			 &:hover {
				 text-decoration: none;
			 }
		 }
	 }
    
	 .hsearch {
		 position: relative;
		 width: 100%;
		 max-width: 350px;
		 height: 35px;
         margin: 0 auto;
         
         @include mobile {
             max-width: 100%;
         }
         
		 input[type="search"] {
			 width: 100%;
			 height: 35px;
			 background: $base_white_color;
			 -webkit-appearance: none;
			 border: 1px solid $base_border_color;
             @include border-radius(3px);
			 padding: 0 15px;
			 font-size: 13px;
             color: $base_light_color;
		 }
         
		 .search_btn {
			 position: absolute;
             top: 0;
             right: 0;
             width: 40px;
             height: 35px;
             cursor: pointer;
             border: none;
             background: none;
             z-index: 4;
             &:before {
                 content: '';
                 position: absolute;
                 top: 50%;
                 left: 50%;
                 width: 19px;
                 height: 19px;
                 margin: -9.5px 0 0 -9.5px;
                 background: url(../images/icon-magnifying-glass.svg) no-repeat 0 0;
                 background-size: contain;
                 @include transition(0.3s ease);
             }
             &:hover {
                 @include transform(scale(1.1));
             }
		 }
	 }
     
	 .error_sale {
		 position: relative;
		 margin-top: 38px;
		 margin-bottom: 40px;
		 border: 4px dashed #d8dadd;
		 background: #eff1f2;
		 z-index: 3;
         padding: 34px 0;
         
         @include tablet {
             display: none; 
         }
         
		 p {
			 margin: 0 0 15px 0;
			 font-size: 14px;
             &:last-child {
                 margin-bottom: 0px;
             }
		 }
         
         strong {
             display: inline-block;
             vertical-align: middle;
             background: $base_white_color;
             height: 29px;
             line-height: 27px;
             padding: 0 12px;
             border: 1px solid #cdcecf;
             @include border-radius(3px);
             font-size: 14px;
             font-weight: 700;
             color: $base_green_color;
         }
         
		 a {
			 color: $base_link_color;
			 border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 border-bottom-color: transparent;
			 }
		 }
         
		 .sale_lt {
			 float: left;
			 padding-right: 57px;
			 width: 50%;
			 text-align: right;
             border-right: 1px solid #d8dadd;
             height: 107px;
             
			 .title {
                 position: relative;
				 font-size: 27px;
				 font-weight: 400;
				 margin: 0 0 20px 0;
				 line-height: 27px;
                 padding-right: 85px;
			 }
             
             .discount {
                 position: absolute;
                 right: 0px;
                 top: -10px;
                 width: 71px;
                 height: 71px;
                 margin-left: 12px;
                 @include border-radius(50%);
                 background: $base_yellow_color;
                 font-size: 37px;
                 line-height: 71px;
                 text-align: center;
			 }
             
			 .code {
				 margin: 0 85px 0 0;
			 }
		 }
		 .sale_rt {
			 float: right;
			 width: 50%;
			 padding-left: 57px;
			 
             strong {
                 font-weight: 400;
                 color: $base_text_color;
                 
                 span {
                     font-weight: 700;
                     color: $base_green_color;
                 }
             }
		 }
	 }
}