/*
    Base
*/

::-webkit-input-placeholder {color: lighten($base_grey_color, 20%);}
::-moz-placeholder          {color: lighten($base_grey_color, 20%);}
:-moz-placeholder           {color: lighten($base_grey_color, 20%);}
:-ms-input-placeholder      {color: lighten($base_grey_color, 20%);}

* {
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: $base_font_size;
    color: $base_text_color;
    font-family: $base_font_family, sans-serif;
    font-weight: 400;
  /*  background: $base_bg url(../images/bg-1.png) repeat-y top center;*/
    
    @include tablet {
        background: $base_white_color;
        padding-top: 72px;
    }
}

a {
	 color: $base_link_color;
	 text-decoration: none;
	 cursor: pointer;
	&:link {
		 color: $base_link_color;
	}
	&:active {
		 color: $base_link_color;
	}
	&:visited {
		 color: $base_link_color;
	}
	&:hover {
		 color: $base_link_color;
		 text-decoration: none; 
	}
	img {
		 border: none;
	}
}

input, textarea, button {
	 font-family: $base_font_family;
}

input[type='checkbox'].nice, input[type='radio'].nice, select.nice {
	opacity: 0;
}

label {
	 cursor: auto;
}

.clr {
	 clear: both;
}

ul {
	 margin: 0;
	 padding: 0;
	 list-style: none;
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
input[type="search"]::-webkit-search-results-decoration { display: none; }

.mob_show {
  display: none;
}

.mobile {
	 display: none;
    
    @include mobile {
        display: block;
    }
}

.desktop {
	 display: block;
    
    @include mobile {
        display: none;
    }
}

.fw {
    width: $base_width;
    margin: 0 auto;
    position: relative;
    &-fixed {
        position: relative;
        background: $base_white_color;
        box-sizing: initial;
        padding: 0 27px;
        overflow: hidden;
        &:before, &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 27px;
            background: $base_white_color;
            top: 0px;
            left: 0px;
        }
        &:after {
            left: auto;
            right: 0px;
        }
        @include desktop {
            padding: 0 12px;
            &:before, &:after {
                width: 12px;
            }
        }
        @include tablet {
            padding: 0;
            &:before, &:after {
                display: none;
            }
        }
    }
    
    @include desktop {
        width: 1140px;
    }
    @include tablet {
        width: 720px;
    }
    @include mobile {
        width: 480px;
    }
    @include mobile-m {
        width: 300px;
    }
}

.bts {
    &:after {
        content: '';
        clear: both;
        display: block;
    }
    &.center {
		 text-align: center;
    }
    &.right {
        .btn {
            float: right;
        }
    }
    .btn {
        display: inline-block;
        vertical-align: top;
        height: 40px;
        line-height: 38px;
        padding: 0 30px;
        font-size: 16px;
        border: 1px solid $base_yellow_color;
        background: $base_yellow_color;
        color: $base_dark_color;
        text-decoration: none;
        text-align: center;
        @include transition(0.25s ease 0s);
        cursor: pointer;
        @include border-radius(3px);
        
        &.border {
            color: $base_blue_color;
            background: transparent;
            border: 1px solid $base_blue_color;
            line-height: 38px;
            font-weight: 600;
            &:hover {
				background: $base_blue_color;
                border-color: $base_blue_color;
				color: $base_white_color;
            }
        }
        &.btn_red {
            background: $base_red_color;
            color: $base_white_color;
            border-color: $base_red_color;
            &:hover {
                border-color: $base_red_color;
                background: $base_hover_color;
            }
        }
        &.btn_white {
			 line-height: 38px;
			 border: 1px solid $base_border_color;
			 background: #fff;
			 color: $base_text_color;
			 
		 }
		 &.btn_small {
			 height: 33px;
			 line-height: 31px;
			 background: #f4f4f4;
		 }
		 &:hover {
			 border-color: $base_yellow2_color;
			 background: $base_yellow2_color;
			 text-decoration: none;
		 }
	 }
}

.centrize {
     display: table !important;
     table-layout: fixed !important;
     height: 100% !important;
     position: relative !important;
}

.vertical-center {
	 display: table-cell !important;
	 vertical-align: middle !important;
}

.owl-prev, .owl-next {
	 position: absolute;
	 left: -20px;
	 top: 50%;
	 margin: -24px 0 0 0;
	 font-size: 0px;
	 opacity: 1;
	 cursor: pointer;
	 z-index: 3;
	 width: 40px;
	 height: 48px;
     border: 1px solid #d7dfe7;
     @include border-radius(3px);
     outline: none;
     background: $base_white_color;
     
     @include desktop {
         left: -4px;   
     }
    
	 &::before, &::after {
		 content: '';
		 position: absolute;
		 top: 50%;
		 left: 50%;
         margin: -10.5px 0 0 -5px;
		 opacity: 1;
		 width: 10px;
         height: 21px;
         background: url(../images/icon-prev.svg) no-repeat 0 0;
         background-size: contain;
         @include transition(opacity 0.25s ease);
	 }
	 &::after {
		 opacity: 0;
		 background: url(../images/icon-prev.svg) no-repeat 0 0;
         background-size: contain;
	 }
    
	 &:hover {
		 &::before {
			 opacity: 0;
		 }
		 &::after {
			 opacity: 1;
		 }
	 }
}

.owl-next {
	 left: auto;
	 right: -20px;
	 &::before, &::after {
		 @include transform(rotate(180deg));
	 }
    
     @include desktop {
         right: -4px;   
     }
}

.owl-dots {
    position: absolute;
    top: auto;
    bottom: 60px;
    left: 0px;
    width: 100%;
    text-align: left;
    height: 10px;
    z-index: 3;
	
    .owl-dot {
        display: inline-block;
        vertical-align: middle;
        margin: 0 12px 0 0;
        width: 7px;
        height: 7px;
        padding: 0;
        @include border-radius(50%);
        background: transparent;
        border: 1px solid $base_grey_color;
        cursor: pointer;
        @include transition(0.25s ease 0s);
        &.active {
            background: $base_blue_color;
            border-color: $base_blue_color;
            width: 10px;
            height: 10px;
        }
    }
}

.owl-dots.disabled{
	display: none;
}

.owl-nav.disabled{
	div{
		display: none;
	}
}

.sprite-icons {
    background-image: url(../images/spritesheet_icons.png);
	background-repeat: no-repeat;
}

.loadmore {
	 padding: 20px 0;
	 text-align: center;
	 .btn {
         padding: 0 20px;
		 font-size: 16px;
		 border-color: $base_dark_color;
		 min-width: 210px;
         height: 45px;
		 line-height: 43px;
		 background: $base_dark_color;
		 color: $base_white_color;
         
		 &:hover {
			 border-color: $base_green_color;
			 background: $base_green_color;
			 color: $base_white_color;
		 }
		 &::before {
			 content: '';
			 display: inline-block;
			 vertical-align: middle;
			 margin-right: 10px;
			 width: 15px;
             height: 17px;
             background: url(../images/icon-reload.svg) no-repeat 0 0;
             background-size: contain;
		 }
	 }
	 .result {
		 display: block;
		 padding: 15px 0 0 0;
		 font-size: 13px;
		 color: $base_dark_color;
	 }
}

.pagination {
	 text-align: center;
	 padding: 10px 0 20px 0;
     
	 .pag {
		 display: inline-block;
		 vertical-align: middle;
		 width: 45px;
		 height: 45px;
         border: 1px solid transparent;
		 line-height: 43px;
		 font-weight: 400;
		 color: $base_text_color;
		 font-size: 16px;
		 text-decoration: none;
         @include border-radius(3px);
         @include transition(0.3s ease);
         
         @include mobile {
             width: 40px;
         }
         
		 &:hover, &.active {
			 color: $base_green_color;
			 text-decoration: none;
			 border-color: $base_green_color;
		 }
         
         @include mobile-m {
             width: 24px;
             font-size: 14px;
         }
	 }
	 span.pag {
		 &:hover {
             border-color: transparent;
			 background: none;
			 color: $base_text_color;
		 }
	 }
	 a.prev, a.next {
         position: relative;
		 display: inline-block;
		 vertical-align: middle;
		 width: 40px;
		 height: 45px;
		 line-height: 43px;
         border: 1px solid #d7dfe7;
		 margin-right: 8px;
         @include transition(0.3s ease);
         @include border-radius(3px);
         
         @include mobile-m {
             width: 30px;
         }
         
		 &:before {
			 content: '';
			 position: absolute;
             top: 50%;
             left: 50%;
             width: 10px;
             height: 21px;
             margin: -10.5px 0 0 -5px;
             background: url(../images/icon-prev.svg) no-repeat 0 0;
             background-size: contain;
		 }
		 &:hover {
             border-color: $base_green_color;
		 }
	 }
	 a.next {
         margin-right: 0px;
		 margin-left: 8px;
		 &:before {
			 transform: scaleX(-1);
		 }
	 }
}

.inner .pagination {
	 margin-bottom: 20px;
}

.back_link {
	 margin: 20px 40px 20px 0;
	 text-align: left;
	 a {
		 position: relative;
		 display: inline-block;
		 vertical-align: top;
		 font-size: 13px;
		 color: $base_link_color;
		 text-decoration: none !important;
		 &:before {
			 content: '';
			 display: inline-block;
             vertical-align: middle;
			 width: 11px;
             height: 7px;
             background: url(../images/icon-arrow-1.svg) no-repeat 0 0;
             background-size: contain;
             margin-right: 10px;
			 @include transition(0.25s ease 0s);
             @include transform(scaleX(-1));
		 }
		 &:hover {
			 text-decoration: none;
			 &:before {
				 @include transform(scaleX(-1) translate3d(5px, 0, 0));
			 }
		 }
 	 }
}

.more_bts {
	 text-align: center;
	 padding: 25px 0 0 0;
	 .btn {
		 margin: 0 7px;
	 }
}

/*Grid*/

/*.row {
	 margin-left: -10px;
	 margin-right: -10px;
	 font-size: 0px;
	 &::after {
		 content: '';
		 display: block;
		 clear: both;
	 }
	 .col {
		 display: inline-block;
		 vertical-align: top;
		 margin: 0 0.78125%;
		 font-size: $base_font_size;
		 &.col1of2 {
			 width: 48.4375%;
		 }
		 &.col1of3 {
			 width: 31.7708%;
		 }
		 &.col1of4 {
			 width: 23.4375%;
		 }
		 &.col1of5 {
			 width: 18.6%;
		 }
		 &.col1of7 {
         	 width: 13.5%;
		 }
	 }
}*/

/*Breadcrumbs*/

.breadcrumbs {
	 padding: 20px 0 0;
	 font-size: 13px;
	 color: $base_dark_color;
	 margin: 0 0 30px 0;
    
     @include mobile {
         margin-bottom: 20px;
     }
    
	 a, span {
		 display: inline-block;
		 vertical-align: middle;
		 padding: 0 10px 0 0;
	 }
    
	 a {
		 color: $base_green_color;
		 &:hover {
			 text-decoration: underline;
		 }
	 }
	 .arr {
         font-size: 0px;
		 display: inline-block;
		 vertical-align: middle;
		 position: relative;
		 top: 1px;
         width: 3px;
         height: 5px;
         background: url(../images/arr_1.svg) no-repeat 0 0;
	 }
}

/*WRAPPER*/

.wrapper {
	 position: relative;
	 z-index: 1;
	 min-height: 600px;
     overflow: hidden;
}

.m_inner {
    padding-bottom: 75px;
}

.m_title {
    position: relative;
    font-size: 27px;
    font-weight: 700;
    color: $base_dark_color;
    margin: 0 0 45px 0;
    text-transform: uppercase;
    padding-bottom: 16px;
    &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 104px;
        height: 1px;
        background: $base_green_color;
        margin-left: -52px;
    }
    
    &.center {
		 text-align: center;
    }
    em {
        display: block;
        font-size: 14px;
        color: #666;
        font-weight: 400;
        font-style: normal;
        font-family: $base_font_family;
    }
    
    @include mobile {
        font-size: 22px;
        margin-bottom: 30px;
        br {
            display: none;
        }
    }
}

.h_title {
    font-size: 27px;
    line-height: 27px;
    font-weight: 700;
    color: $base_dark_color;
    margin: 0 0 15px 0;
    text-transform: uppercase;
    text-align: center;
    
    span {
        display: inline-block;
        vertical-align: top;
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 44px;
            height: 1px;
            background: $base_green_color;
            
            @include tablet {
                display: none;
            }
        }
        &:before {
            left: -60px;
        }
        &:after {
            right: -60px;
        }
    }
    
    @include mobile {
        font-size: 22px;
        margin-bottom: 30px;
        br {
            display: none;
        }
    }
}

.l_title {
    font-size: 14px;
    font-weight: 700;
    color: $base_text_color;
    margin: 12px 0 25px 0;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}

.sidebanners {
	 display: none;
	 .sidebanner_lt {
		 position: absolute;
		 top: 180px;
		 left: -160px;
		 width: 140px;
		 img {
			 max-width: 100%;
		 }
	 }
	 .sidebanner_rt {
		 position: absolute;
		 top: 180px;
		 right: -160px;
		 width: 140px;
		 img {
			 max-width: 100%;
		 }
	 }
}

.column {
	 &.column2 {
		 .lt_column {
			 width: 270px;
             
             @include tablet {
                 display: none;
             }
		 }
		 .rt_column {
			 margin-left: 300px;
             
             @include tablet {
                 margin-left: 0px;
             }
		 }
	 }
	 &.column3 {
		 .lt_column {
			 float: right;
			 width: 170px;
             
             @include tablet {
                 display: none;
             }
		 }
		 .rt_column {
			 margin-right: 200px;
			 margin-left: 0px;
             
             @include tablet {
                 margin-right: 0px;
             }
		 }
	 }
}

.lt_column {
    float: left;
    width: 292px;
	 
    .title {
        font-size: 27px;
        font-weight: 400;
        color: $base_dark_color;
        margin: -52px 0 20px 0;
    }
    
    @include tablet {
        display: none;
    }
    
    .back_link {
        margin-right: 0px;
    }
}

.left_menu {
    margin: 0 0 30px 0;
    background: $base_bg1;
    padding: 5px 30px 28px 30px;
    
    & > ul {
        & > li {
            position: relative;
            margin: 0;
            & > a {
                 position: relative;
                 display: block;
                 color: $base_text_color;
                 text-decoration: none;
                 height: 68px;
                 @include flex-column;
                 justify-content: center;
                 border-bottom: 1px solid $base_border_color;
                 font-size: 14px;
                 font-weight: 700;
                 text-transform: uppercase;
                 letter-spacing: 0.02em;
                 &:hover {
                     color: $base_blue_color;
                     text-decoration: none;
                 }
            }
            &.parent {
                & > a {
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        margin-top: -4px;
                        @include stitches-sprite(-20px, -746px, 13px, 8px);
                    }
                    &:after {
                        @include stitches-sprite(-20px, -794px, 13px, 8px);
                        opacity: 0;
                    }
                }
            }
            &:hover {
                & > a {
                    color: $base_blue_color;
                    &:after {
                        opacity: 1;
                    }
                }
            }
            &.active {
                & > a {
                    color: $base_blue_color;
                    &:after {
                        opacity: 1;
                    }
                }
                & > ul {
                    display: block;
                }
            }
            &.open {
                & > a {
                    color: $base_blue_color;
                    &:after {
                        opacity: 1;
                    }
                }
                & > ul {
                    display: block;
                }
            }
            & > ul {
                display: none;
                border-bottom: 1px solid $base_border_color;
                padding-bottom: 30px;
                & > li {
                    position: relative;
                    padding-left: 12px;
                    margin: 15px 0 0 0;
                    font-size: 13px;
                    font-weight: 400;
                    text-transform: none;
                    a {
                        color: $base_blue_color;
                        text-decoration: none;
                        
                        &:before {
                             content: '';
                             position: absolute;
                             left: 0;
                             top: 8px;
                             width: 3px;
                             height: 3px;
                             background: $base_blue_color;
                             @include border-radius(3px);
                        }
                        &:hover {
                            color: $base_red_color;
                            &:before {
                                background: $base_red_color;
                            }
                        }
                    }
                    &.active {
                        a {
                            color: $base_red_color;
                            &:before {
                                background: $base_red_color;
                            }
                        }
                    }
                    &.open {
                        a {
                            color: $base_red_color;
                            &:before {
                                background: $base_red_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.banner {
	 position: relative;
	 margin: 0 0 30px 0;
	 font-size: 0px;
}

.rt_column {
    margin-left: 322px;
    min-height: 600px;
    @include flex-column;
    
    @include tablet {
        margin-left: 0px;
    }
    
    .inner {
        padding: 20px 40px 40px 40px;
    }
}

.inner {
	 padding: 20px 0 40px 0;
}

/* Top Banner */

.alert {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background: $base_yellow_color;
    z-index: 100;
    text-align: center;
    
    @include tablet {
        display: none;
    }
    
    .fw {
        position: relative;
        height: 40px;
        line-height: 40px;
    }
	 .al_title {
		 position: relative;
         display: inline-block;
         vertical-align: top;
		 color: $base_dark_color;
		 font-size: 16px;
		 a {
			 color: $base_dark_color;
			 text-decoration: underline;
			 &:hover {
				 text-decoration: none;
			 }
		 }
	 }
	 .close {
		 position: absolute;
		 top: 0px;
		 right: 0;
		 width: 10px;
         height: 100%;
         &:before, &:after {
             content: '';
             position: absolute;
             top: 50%;
             left: 50%;
             width: 14px;
             height: 2px;
             @include border-radius(50%);
             background: $base_dark_color;
             transform-origin: center center;
             @include transform(rotate(-45deg));
             margin-left: -7px;
         }
         &:after {
             @include transform(rotate(45deg));
         }
	 }

    & + .header {
        margin-top: 40px;
		@include tablet{
			margin-top: 0px;
		}
    }
}

/*readmore*/

.bts {
    .btn_readmore {
         position: relative;
         display: inline-block;
         vertical-align: top;
         padding: 0 10px;
         border: 1px solid $base_link_color;
         @include border-radius(3px);
         font-size: 13px;
         cursor: pointer;
         text-decoration: none;
         color: $base_green_color;
         height: 26px;
         line-height: 24px;
         background: none;
         text-transform: initial;
        
         &:after {
             content: '';
             display: inline-block;
             vertical-align: middle;
             width: 11px;
             height: 7px;
             background: url(../images/icon-arrow-1.svg) no-repeat 0 0;
             background-size: contain;
             margin-left: 3px;
         }
         &:hover {
             background: $base_green_color;
             color: $base_white_color;
             &:after {
                 background: url(../images/icon-arrow-1h.svg) no-repeat 0 0;
                 background-size: contain;
             }
         }
    }
}

*[aria-expanded="false"] {
	 position: relative;
	 overflow: hidden;
}
.readmore[data-readmore-toggle] {
	 position: relative;
     
     a {
         position: relative;
         display: inline-block;
         vertical-align: top;
         padding: 0 10px;
         border: 1px solid $base_link_color;
         @include border-radius(3px);
         font-size: 13px;
         cursor: pointer;
         text-decoration: none;
         color: $base_green_color;
         height: 26px;
         line-height: 24px;
         &:after {
             content: '';
             display: inline-block;
             vertical-align: middle;
             width: 11px;
             height: 7px;
             background: url(../images/icon-arrow-1.svg) no-repeat 0 0;
             background-size: contain;
             margin-left: 3px;
         }
         &:hover {
             background: $base_green_color;
             color: $base_white_color;
             &:after {
                 background: url(../images/icon-arrow-1h.svg) no-repeat 0 0;
                 background-size: contain;
             }
         }
     }
    
	 &.open {
		 &::after {
			 display: none;
		 }
	 }

	 &::after {
		 content: '';
		 position: absolute;
		 top: -20px;
		 left: -5%;
		 width: 110%;
		 height: 20px;
		 background: $base_white_color;
		 @include box-shadow(0px -10px 20px #fff);
		 z-index: 2;			 
	 }
}

/* Zoom */

.zoomContainer{
	z-index: 1000;
}

.zoomHeader {
	background: none repeat scroll 0 0 #797979;
	color: $base_white_color;
	display: block;
	font-size: 10px;
	height: 18px;
	left: 0;
	line-height: 18px;
	opacity: 0.6;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 120;
}

/* Forms */

.c_form {
    .c_title {
        font-size: 24px;
        line-height: 24px;
        color: $base_text_color;
        font-weight: 700;
        margin: 0 0 25px 0;
        text-align: center;
        
        span {
            font-size: 16px;
            font-weight: 400;
            margin: 5px 0 0 0;
            display: block;
            line-height: normal;
        }
	 }
	 .group {
		 margin-bottom: 12px;
		 label {
			 display: block;
			 margin-bottom: 5px;
			 span {
				 font-weight: 400;
				 display: inline-block;
				 vertical-align: middle;
                 color: $base_red_color;
				 padding: 0 0 0 4px;
			 }
		 }
		 .value {
			 input, textarea {
				 width: 100%;
				 height: 35px;
				 border: 1px solid $base_border_color;
                 @include border-radius(3px);
				 background: $base_white_color;
				 padding: 0 20px;
				 font-family: $base_font_family;
				 font-size: $base_font_size;
				 color: $base_dark_color;
                 &:focus {
                     border-color: $base_green_color;
                 }
				 &.error {
					 border-color: $base_red_color;
				 }
			 }
			 textarea {
				 height: 100px;
				 padding: 20px;
			 }
			 label.error {
				 margin-top: 8px;
				 font-size: 12px;
				 color: $base_red_color;
			 }

		 }
		 .link {
			 color: $base_link_color;
			 text-decoration: none;
			 border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 border-bottom-color: transparent;
			 }
		 }
	 }
	 .bts {
		 .inf {
			 float: left;
             line-height: 40px;
		 }
	 }
     .inf {
         font-size: 13px;
         span {
             display: inline-block;
             vertical-align: middle;
             padding-right: 4px;
             color: $base_red_color;
         }
     }
     .info {
         color: #7b8289;
         font-size: 12px;
         a {
             color: $base_green_color;
             text-decoration: none;
             border-bottom: 1px dotted $base_green_color;
             &:hover {
                 border-bottom-color: transparent;
                 text-decoration: none;
             }
         }
     }
	 .alert_send {
		 display: none;
	 }
}

/*Number Info*/

.num_info {
	position: absolute;
    left: 0px;
    top: 45px;
    white-space: nowrap;
    padding: 7px 10px;
    font-size: 12px;
    background: $base_white_color;
    color: $base_text_color;
    border: 1px solid $base_green_color;
    @include border-radius(3px);
    text-align: center;
    visibility: hidden; 
    opacity: 0;
    @include transition(0.25s ease);
    
    &:before {
        content: '';
        position: absolute;
        left: 36px;
        top: -8px;
        width: 18px;
        height: 10px;
        background: url(../images/info_arr.png) no-repeat 0 0;
    }
    &.active {
        visibility: visible;
        opacity: 1;
    }
}

/*Nice Scroll*/

body {
	&.no-scroll {
		overflow: hidden;
	}

    .jspTrack {
        background: $base_bg0;
    }
    .jspDrag {
        background: $base_yellow_color;
    }
}

/*Search 404*/

.wrapper {
    .searchTipsList .errExample {
        @include mobile {
            margin-left: 0px;
        }
    }
}

.cheaper_box  {
.frm_buy_item {
	display:flex;
}
	.cat_item {
		width:250px;
		float:left;
	}
	.plus_ic,.equally_ic {
		width:40px;
		position:relative;
		float:left;
		font-size:40px;
		&:before{
			content:"+";
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
		}


	}
	.equally_ic {
		&:before {
			content:'=';
		}
	}
}