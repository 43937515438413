/*
    Contacts
*/

.contacts_info {
	 padding: 20px 0;
     margin-bottom: 40px;
     
	 .lt {
		 float: left;
		 width: 375px;
         font-size: 16px;
         
         @include tablet {
             float: none;
             width: auto;
         }
         
		 ul {
             padding: 40px 0 0 0;
			 li {
				 position: relative;
				 padding-left: 34px;
				 margin-bottom: 20px;
                 
                 @include tablet {
                     display: inline-block;
                     vertical-align: top;
                     width: 48%;
                     margin-bottom: 30px;
                 }
                 @include mobile-m {
                     display: block;
                     width: auto;
                     margin-bottom: 20px;
                     &:last-child {
                         margin-bottom: 40px;
                     }
                 }
                 
				 &:before {
					 content: '';
					 position: absolute;
					 left: 0;
					 top: 0px;
				 }
				 &.c_adr {
					 &:before {
						 width: 20px;
                         height: 23px;
                         background: url(../images/icon-loc.svg) no-repeat 0 0;
                         background-size: contain;
					 }
				 }
				 &.c_tel {
					 &:before {
						 width: 19px;
                         height: 19px;
                         background: url(../images/icon-phone.svg) no-repeat 0 0;
                         background-size: contain;
					 }
				 }
                 &.c_email {
					 &:before {
						 width: 19px;
                         height: 14px;
                         background: url(../images/icon-email.svg) no-repeat 0 0;
                         background-size: contain;
                         top: 2px;
					 }
				 }
				 &.c_time {
					 &:before {
						 width: 19px;
                         height: 19px;
                         background: url(../images/icon-time.svg) no-repeat 0 0;
                         background-size: contain;
					 }
				 }
				 .name {
					 display: block;
                     font-weight: 700;
                     margin: 0 0 4px 0;
				 }
			 }
		 }
		 h3 {
			 font-size: 23px;
			 font-weight: 400;
			 margin: 0 0 5px 0;
		 }
		 p {
			 line-height: normal;
			 margin: 0;
		 }
		 a {
			 color: $base_link_color;
			 text-decoration: none;
             border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 text-decoration: none;
                 border-bottom-color: transparent;
			 }
		 }
	 }
     .rt {
         float: right;
         width: 720px;
         
         @include tablet {
             float: none;
             width: auto;
         }
     }
}

.c_map {
    h3 {
        font-size: 23px;
        font-weight: 400;
        margin: 0 0 20px 0;
    }
    
    #mapContactCanvas {
         width: 100%;
         height: 393px;
         margin: 0 0 40px 0;
    }
}

.contacts_form {
    padding: 20px 65px;
    border: 1px solid #d3d3d3;
    @include border-radius(3px);
    
    @include mobile {
        padding: 20px 30px;
    }
    
    @include mobile-m {
        padding: 20px 15px;
    }
    
    .form-row {
        margin-left: -12px;
        margin-right: -12px;
        
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
    
    .form-col {
        float: left;
        width: 50%;
        padding: 0 12px;
        
        @include mobile {
            width: 100%;
        }
    }
    
    .form-submit {    
        .value {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            @include mobile {
                display: block;
            }
        }
        .img_captcha{
          display: none;
        }
        img {
            @include mobile {
                display: block;
            }
        }
        .bts {
            width: 146px;
            
            .btn {
                display: block;
                padding: 0 10px;
                &:after {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 15px;
                    width: 19px;
                    height: 19px;
                    background: url(../images/icon-check.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            
            @include mobile {
                float: right;
            }
            @include mobile-m {
                float: none;
                text-align: center;
                width: auto;
                margin-bottom: 12px;
                
                .btn {
                    display: inline-block;
                    padding: 0 30px;
                }
            }
        }
        .field {
            width: 258px;
            margin: 2.5px 0 0 0;
            
            @include mobile {
                float: left;
            }
            @include mobile-m {
                float: none;
                margin: 0 0 12px 0;
                width: 100%;
            }
        }
    }
    
    &.c_form {
        .group {    
            .value {
                textarea {
                    height: 175px;
                }
            }
        }
    }
    
    .inf {
        float: left;
        width: 160px;
        margin-bottom: 20px;
        
        @include mobile {
            float: none;
            width: auto;
        }
        
        em {
            font-style: normal;
            max-width: 90%;
            display: inline-block;
            vertical-align: top;
        }
    }
    .info {
        float: right;
        width: 340px;
        
        @include mobile {
            float: none;
            width: auto;
        }
    }
}