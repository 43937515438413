/*
    Product
*/

.m_product {
    padding-bottom: 0px;
}

.product {
    &_top {
        padding: 0 0 30px 0;
        
        .pr_left {
            float: left;
            width: 572px;
            position: relative;
            
            @include tablet {
                width: 320px;
            }
            @include mobile {
                width: 470px;
                float: none;
                margin: 0 auto;
                margin-bottom: 30px;
            }
            @include mobile-m {
                width: auto;
            }
            
            .stickers {
                 position: absolute;
                 top: 20px;
                 left: 0px;
                 width: 100%;
                 z-index: 2;
                 @include transition(0.3s ease 0s);
                 text-align: left;
                 .sticker {
                     display: inline-block;
                     vertical-align: top;
                     padding: 0 7px;
                     height: 24px;
                     line-height: 24px;
                     text-align: center;
                     font-size: 12px;
                     color: $base_white_color;
                     text-transform: uppercase;
                     &.sale {
                         background: $base_red_color;
                     }
                     &.hit {
                         background: #fea100;
                     }
                     &.new {
                         background: $base_green_color;
                     }
                     &.discount {
                         position: absolute;
                         top: 0px;
                         right: 9px;
                         width: 36px;
                         height: 36px;
                         line-height: 36px;
                         text-align: center;
                         background: #ff592d;
                         @include border-radius(50%);
                         font-size: 12px;
                         font-weight: 700;
                     }
                 }
            }
        }
        .pr_images {
            position: relative;
            border: 1px solid #d7dfe7;
            @include border-radius(4px);
            margin: 0 0 25px 0;
        }
        .b_images {
            position: relative;
            overflow: hidden;
            font-size: 0px;
            margin: 0 0 10px 0;
            @include border-radius(4px);
            a {
                display: none;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 74px;
                    height: 69px;
                    margin: -34.5px 0 0 -37px;
                    background: rgba(255,255,255,0.75);
                    z-index: 2;
                    opacity: 0;
                    @include transition(0.3s ease 0s);
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 27px;
                    height: 27px;
                    margin: -13.5px 0 0 -13.5px;
                    background: url(../images/icon-zoom.svg) no-repeat 0 0;
                    background-size: contain;
                    opacity: 0;
                    @include transition(0.3s ease 0s);
                    z-index: 3;
                }
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
                &.active {
                    display: block;
                }
            }
            img {
                max-width: 100%;
            }
        }
        .s_images {
            position: relative;
            padding: 18px 0;
            border-top: 1px solid #d7dfe7;
            
            .owl-carousel {
                padding: 0 105px;
                
                @include mobile-m {
                    padding: 0 50px;
                }
            }
            
            .item {
                a {
                    position: relative;
                    display: block;
                    text-align: center;
                    font-size: 0px;
                    
                    &:hover {
                        &:before {
                            opacity: 0;
                        }
                        &:after {
                            opacity: 0;
                        }
                    }
                    &.active {
                        img {
                            border-color: $base_green_color;
                        }
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 50px;
                        height: 50px;
                        margin: -25px 0 0 -25px;
                        background: rgba(255,255,255,0.75);
                        z-index: 2;
                        opacity: 0;
                        @include border-radius(50%);
                        @include transition(0.3s ease 0s);
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 24px;
                        height: 24px;
                        margin: -12px 0 0 -12px;
                        background: url(../images/icon-zoom.svg) no-repeat 0 0;
                        background-size: contain;
                        z-index: 3;
                        opacity: 0;
                        @include transition(0.3s ease 0s);
                    }
                    img {
                        max-width: 100%;
                        border: 1px solid #d7dfe7;
                        @include transition(0.3s ease 0s);
                    }
                }
            }
            .owl-prev {
                left: 55px;
                
                @include mobile-m {
                    left: 6px;
                }
            }
            .owl-next {
                right: 55px;
                
                @include mobile-m {
                    right: 6px;
                }
            }
        }
        
        .share {
            float: left;
            width: 42%;
            line-height: 43px;
            
            @include tablet {
                float: none;
                width: auto;
                line-height: normal;
            }
            @include mobile {
                text-align: center;
            }
            
            & > span {
                padding-right: 10px;
            }
            & > img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        
        .pr_labels {
            float: right;
            width: 58%;
            text-align: right;
            
            @include tablet {
                float: none;
                width: auto;
                margin-bottom: 20px;
                text-align: center;
            }
            
            .label {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 43px;
                height: 43px;
                margin-left: 8px;
                @include border-radius(3px);
                @include transition(0.3s ease);
                background: #e7e9ea;
                
                &:hover {
                    .info_tip {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                
                .info_tip {
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    left: 0px;
                    top: 54px;
                    &:before {
                        left: 12px;
                    }
                    &:after {
                        display: none;
                    }
                }
                
                @include mobile-m {
                    width: 38px;
                }
                
                &:first-child {
                    margin-left: 0px;
                }
                &.active {
                    background: $base_yellow_color;
                    &:before {
                        opacity: 1;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    opacity: 0.6;
                    @include transition(0.3s ease);
                }
                
                &-21 {
                    &:before {
                        width: 31px;
                        height: 26px;
                        margin: -13px 0 0 -15.5px;
                        background: url(../images/pr-l-1.png) no-repeat 0 0;
                    }
                }
                &-22 {
                    &:before {
                        width: 31px;
                        height: 27px;
                        margin: -13.5px 0 0 -15.5px;
                        background: url(../images/pr-l-2.png) no-repeat 0 0;
                    }
                }
                &-23 {
                    &:before {
                        width: 31px;
                        height: 26px;
                        margin: -13px 0 0 -15.5px;
                        background: url(../images/pr-l-3.png) no-repeat 0 0;
                    }
                    
                    @include mobile-m {
                        .info_tip {
                            left: -50px;
                            &:before {
                                left: auto;
                                left: 60px;
                            }
                        }
                    }
                }
                &-24 {
                    &:before {
                        width: 31px;
                        height: 24px;
                        margin: -12px 0 0 -15.5px;
                        background: url(../images/pr-l-4.png) no-repeat 0 0;
                    }
                    
                    @include mobile-m {
                        .info_tip {
                            left: -100px;
                            &:before {
                                left: auto;
                                right: 90px;
                            }
                        }
                    }
                }
                &-25 {
                    &:before {
                        width: 29px;
                        height: 27px;
                        margin: -13.5px 0 0 -14.5px;
                        background: url(../images/pr-l-5.png) no-repeat 0 0;
                    }
                    
                    @include mobile {
                        .info_tip {
                            left: auto;
                            right: 0px;
                            &:before {
                                left: auto;
                                right: 12px;
                            }
                        }
                    }
                }
                &-26 {
                    &:before {
                        width: 25px;
                        height: 25px;
                        margin: -12.5px 0 0 -12.5px;
                        background: url(../images/pr-l-6.png) no-repeat 0 0;
                    }

                    @include mobile {
                        .info_tip {
                            left: auto;
                            right: 0px;
                            &:before {
                                left: auto;
                                right: 12px;
                            }
                        }
                    }
                }
            }
        }
        
        .pr_right {
            margin-left: 614px;
            
            @include tablet {
                margin-left: 340px;
            }
            @include mobile {
                margin-left: 0px;
            }
            
            .h_title {
				margin: 0 0 25px 0;
            }
        }
        .pr_desc {
            margin: 0;
            
            .code {
				font-size: 14px;
				color: rgba(31,38,44,0.8);
                line-height: 27px;
                strong {
                    font-weight: 700;
                }
            }
            .stock {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 122px;
                height: 27px;
                margin-left: 10px;
                border: 1px solid #bbbdbf;
                @include border-radius(3px);
                line-height: 25px;
                text-align: center;
                font-size: 13px;
                
                @include mobile-m {
                    width: 112px;
                    margin-right: 0px;
                }
                
                &:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                }
                &.stock_in {
                    color: $base_green_color;
                    &:before {
                        width: 10px;
                        height: 7px;
                        background: url(../images/icon-stock-in.svg) no-repeat 0 0;
                    }
                }
                &.stock_out {
                    color: rgba(31,38,44,0.8);
                    &:before {
                        top: -1px;
                        width: 8px;
                        height: 8px;
                        background: url(../images/icon-stock-out.svg) no-repeat 0 0;
                    }
                }
                &.stock_ord {
                    color: rgba(255,61,16,0.8);
                    &:before {
                        top: -1px;
                        width: 11px;
                        height: 11px;
                        background: url(../images/icon-stock-order.svg) no-repeat 0 0;
                    }
                }
            }
            .print_btn {
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;
                width: 27px;
                height: 27px;
                border: 1px solid $base_blue_color;
                @include border-radius(3px);
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 15px;
                    height: 16px;
                    margin: -8px 0 0 -7.5px;
                    background: url(../images/icon-print.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            .readmore {
                margin: 15px 0 0 0;
                
                a {
                    padding: 0;
                    border: none;
                    font-size: 15px;
                    color: $base_text_color;
                    border-bottom: 1px dotted $base_text_color;
                    @include border-radius(0px);
                    line-height: 15px;
                    height: auto;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        background: none;
                    }
                }
            }
            .pr_info_text {
                font-size: 13px;
                line-height: 15px;
                padding: 10px 14px;
                border: 1px dashed $base_green_color;
                @include border-radius(5px);
                color: rgba(31,38,44,0.8);
                
                &_1 {
                    font-size: 14px;
                    padding-left: 24px;
                    padding-right: 24px;
                    strong {
                        color: rgba(255,61,16,0.8);
                    }
                }
                &_2 {
                    border-color: $base_yellow_color;
                }
            }
		 }
         .pr_params {
            &_1 {
                padding-bottom: 10px;
                border-bottom: 1px solid #d7dfe7;
                margin-bottom: 20px;
                
                .pr_param {
                    width: 49%;
                    
                    @include mobile-m {
                        &:nth-child(1) {
                            width: 44%;
                        }
                        &:nth-child(2) {
                            width: 55%;
                        }
                    }
                    
                    &:last-child {
                        text-align: right;
                    }
                }
            }
            &_2 {
                margin-left: -5px;
                margin-right: -5px;
                @include flex-row;
                
                @include tablet {
                    display: block;
                    margin: 0;
                }
                
                .pr_param {
                    display: block;
                    width: 100%;
                    margin: 0 5px;
                    
                    @include tablet {
                        margin: 0 0 10px 0;
                    }
                    
                    &:last-child {
                        min-width: 55%;
                    }
                }
            }
        }
        .pr_param {
            display: inline-block;
            vertical-align: top;
        }
        .pr_chars {
            padding: 0 0 28px 0;
            font-size: 16px;
            color: rgba(31,38,44,0.8);
            
            h3 {
                font-size: 20px;
                font-weight: 700;
                margin: 0 0 12px 0;
            }
            
            table {
                width: 100%;

                tr:last-child {
                    td {
                        border-bottom: none;
                    }
                }
                td {
                    min-width: 72px;
                    padding: 5px 10px 5px 0;
                    border-bottom: 1px dotted #eaeced;
                    &:last-child {
                        font-weight: 700;
                    }
                }
            }
        }
        .pr_price {
            padding: 15px 0 18px 0;
            border-top: 1px solid #d7dfe7;
            border-bottom: 1px solid #d7dfe7;
            overflow: hidden;
            
            &:after {
                content: '';
                clear: both;
                display: block;
            }
            
            .prices {
                float: left;
                width: 30%;
                
                @include desktop {
                    width: 40%;
                }
                @include tablet {
                    float: none;
                    width: auto;
                }
                
                label {
                    display: block;
                    font-size: 22px;
                    color: #8c8e91;
                    margin-bottom: 6px;
                }
                .old_price {
                    font-size: 13px;
                    color: $base_grey_color;
                    text-decoration: line-through;
                }
                .new_price {
                    font-size: 26px;
                    line-height: 39px;
                    color: $base_dark_color;
                    white-space: nowrap;
                    strong {
                        font-size: 39px;
                        font-weight: 700;
                    }
                }
            }
            .price_info {
                float: right;
                width: 70%;
                padding: 5px 0;
                min-height: 66px;
                padding-left: 24px;
                border-left: 1px solid #d7dfe7;
                font-size: 13px;
                line-height: 18px;
                color: rgba(31,38,44,0.6);
                
                @include desktop {
                    width: 60%;
                }
                @include tablet {
                    float: none;
                    width: auto;
                    border-left: none;
                    padding-left: 0px;
                }

                .discounts_list {
                    .discount_row {
                        font-size: 24px;
                        line-height: 36px;
                    }
                    .discount_row .price_d{
                        font-size: 36px;
                    }
                }
            }
        }
        .pr_buy {
            padding: 24px 0;
            font-size: 0px;
            overflow: hidden;
            
            &:after {
                content: '';
                display: block;
                clear: both;
            }
            .count {
                float: left;
                position: relative;
                width: 135px;
                margin-right: 20px;
                
                @include tablet {
                    margin-bottom: 15px;
                }
                @include mobile-m {
                    float: none;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .buy_bts {
                float: left;
                
                @include mobile-m {
                    float: none;
                }
                
                .btn {
                    width: 149px;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 20px;
                    height: 44px;
                    line-height: 42px;
                    font-size: 13px;
                    text-transform: uppercase;
                    @include border-radius(3px);
                    
                    @include mobile-m {
                        display: block;
                        width: 100%;
                        margin: 0;
                    }
                }

                .buy_btn {
                    padding: 0 38px 0 0;
                    border: 1px solid #8d9093;
                    background: $base_white_color;
                    color: $base_dark_color;
                    
                    @include mobile-m {
                        margin-bottom: 15px;
                    }
                    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        width: 38px;
                        height: 100%;
                        z-index: 2;
                        background: $base_yellow_color;
                        @include transition(0.3s ease 0s);
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        margin-top: -8.5px;
                        width: 19px;
                        height: 17px;
                        background: url(../images/icon-shopping-cart.svg) no-repeat 0 0;
                        background-size: contain;
                        z-index: 3;
                    }
                    &:hover {
                        background: $base_yellow2_color;
                    }
                }
                
                .click_btn {
                    padding: 0 10px;
                    border: 1px solid $base_green_color;
                    background: $base_white_color;
                    color: $base_green_color;

                    &:hover {
                        color: $base_white_color;
                        background: $base_green_color;
                    }
                }
            }
            .empty_bts {
				padding: 25px 0;
				text-align: center;
                
				.btn {
				    max-width: 100%;
				    padding: 0 5px;
                    display: block;
                    //font-size: 13px;
                    
                    &.BtnGetPrice {
                        font-size: 13px;    
                    }
				}
            }
        }
    }
    &_bottom {
        margin: 30px 0 0 0;
        
        @include mobile-m {
            margin: 0;
        }
        
        .prod_tabs {
		 	 margin-bottom: 100px;
            
            @include mobile-m {
                margin-bottom: 40px;
            }
        }
        .tab_menu {
            height: 64px;
            margin: 0 0 50px 0;
            border-bottom: 4px solid #eaeced;
            text-align: center;
            
            ul {
				font-size: 0;
				li {
                    position: relative;
				    display: inline-block;
				    vertical-align: top;
				    margin: 0 25px;
				    border-bottom: 4px solid transparent;
				    height: 64px;
                    line-height: 60px;
                    @include transition(0.3s ease 0s);
                    
                    @include mobile {
                        margin: 0 10px;
                    }
                    
				    a {
				        position: relative;
				        display: block;
                        padding: 0 6px;
				        font-size: 18px;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #8c8e91;
                        @include transition(0.3s ease 0s);
                        
                        @include mobile {
                            padding: 0;
                        }
                        @include mobile-m {
                            font-size: 13px;
                        }
                        
                        &:hover {
                            color: $base_dark_color;
                        }
				    }
				    &.active {
                        border-bottom-color: $base_green_color;
                        
				        a {
				            color: $base_dark_color;
				        }
				    }
                }
            }
        }
        .tab_wrapper {
            
            .tab_content {
				display: none;
                
				&::after {
				    content: '';
				    display: block;
				    clear: both;
				}
                
				&.active {
				    display: block;
				}
			 }
		 }
        
         .text_box {
             h2 {
                 text-align: center;
                 font-size: 21px;
                 font-weight: 400;
                 text-transform: initial;
                 max-width: 620px;
                 margin-left: auto;
                 margin-right: auto;
                 line-height: normal;
             }   
         }
	 }
}

/*Carousel*/

.prods_module {
    margin: 40px 0;
    
    @include mobile-m {
        margin-bottom: 10px;
    }
}

/* Product Bottom Info */

.m_info {
    padding: 63px 0 73px 0;
    background: #eaeced;
    
    @include mobile-m {
        padding: 40px 0;
    }
    
    .row {
        margin-left: -15px;
        margin-right: -15px;
        font-size: 0px;
        
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        
        @include tablet {
            text-align: center;
        }
        @include mobile-m {
            margin: 0;
        }
    }
    
    .col {
        display: inline-block;
        vertical-align: top;
        width: 397px;
        background: $base_white_color;
        min-height: 337px;
        margin: 0 15px;
        border: 1px solid #d7dfe7;
        padding: 20px 50px 0;
        font-size: 16px;
        text-align: left;
        
        @include desktop {
            width: 359px;
            padding-left: 30px;
            padding-right: 30px;
        }
        
        @include tablet {
            width: 345px;
            margin-bottom: 30px;
        }
        @include mobile {
            &:last-child {
                margin-bottom: 0px;
            }
        }
        @include mobile-m {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 10px;
            padding-right: 10px;
        }
        
        &-1 {
            li {
                padding-left: 48px;
                span {
                    position: relative;
                    padding-left: 50px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0px;
                    }
                }
                &.ben-1 {
                    span:before {
                        width: 28px;
                        height: 25px;
                        margin-top: -12.5px;
                        background: url(../images/icon-pr-ben-1.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                &.ben-2 {
                    span:before {
                        width: 23px;
                        height: 29px;
                        margin-top: -14.5px;
                        background: url(../images/icon-pr-ben-2.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                &.ben-3 {
                    span:before {
                        width: 29px;
                        height: 29px;
                        margin-top: -14.5px;
                        background: url(../images/icon-pr-ben-3.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                &.ben-4 {
                    span:before {
                        width: 28px;
                        height: 28px;
                        margin-top: -14px;
                        background: url(../images/icon-pr-ben-4.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
            }
        }
        &-3 {
            li {
                padding-left: 40px;
                
                &.tel, &.email, &.phone {
                    font-size: 18px;
                    span {
                        position: relative;
                        padding-left: 32px;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0px;
                        }
                    }
                }
                &.tel {
                    font-size: 26px;
                    
                    span:before {
                        width: 19px;
                        height: 19px;
                        margin-top: -9.5px;
                        background: url(../images/icon-tel.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                &.email {
                    span:before {
                        width: 19px;
                        height: 13px;
                        margin-top: -6.5px;
                        background: url(../images/icon-email.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                &.phone {
                    span:before {
                        width: 19px;
                        height: 19px;
                        margin-top: -9.5px;
                        background: url(../images/icon-tel-3.svg) no-repeat 0 0;
                        background-size: contain;
                    }
                }
            }
        }
    }
    
    .title {
        position: relative;
        padding: 0 0 0 40px;
        margin: 0 0 18px 0;
        line-height: 49px;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        
        @include tablet {
            padding-left: 30px;
        }
        @include mobile-m {
            padding-left: 10px;
            &:before, &:after {
                display: none;
            }
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 30px;
            height: 1px;
            background: $base_green_color;
        }
        &:before {
            left: -20px;
        }
        &:after {
            right: -20px;
        }
        
        i {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 49px;
            height: 49px;
            background: $base_green_color;
            @include border-radius(50%);
            margin-right: 15px;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
            }
            &.icon-1 {
                &:before {
                    width: 35px;
                    height: 25px;
                    margin: -12.5px 0 0 -17.5px;
                    background: url(../images/icon-pr-b-i1.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-2 {
                &:before {
                    width: 29px;
                    height: 20px;
                    margin: -10px 0 0 -14.5px;
                    background: url(../images/icon-pr-b-i2.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-3 {
                &:before {
                    width: 28px;
                    height: 28px;
                    margin: -14px 0 0 -14px;
                    background: url(../images/icon-pr-b-i3.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
        }
    }
    
    li {
        min-height: 56px;
        @include flex-column;
        justify-content: center;
        border-bottom: 1px solid #dcddde;
        font-size: 16px;
        line-height: 20px;
        padding: 6px 20px;
        
        a {
            color: $base_green_color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        
        &:last-child {
            border-bottom: none;
        }
    }
}

@media print {
    .breadcrumbs {
        display: none !important;
    }
    .product_top .pr_buy,
    .product_top .pr_params_2,
    .product_top .share,
    .product_top .s_images,
    .product_bottom,
    .m_info,
    .main_menu,
    .header .hright,
    .header .hsearch,
    .alert,
    .mob_btn,
    .product_top .pr_labels,
    .product_top .pr_desc .print_btn,
     .newstickers.newsticker-1,
    .footer {
        display: none !important;
    }
    .product_top {
        padding-top: 40px;
    }
    .product_top .pr_images {
        margin-bottom: 0px;
    }
    .product_top .pr_price {
        border: none;
    }
    .product_top .pr_price .price_info {
      /*  display: none !important;*/
    }
    .alert + .header {
        margin-top: 0px;
    }
    .header {
        position: static;
        .hcenter {
            float: right;
            width: 200px;
        }

        .contacts {
            display: block !important;
            margin-top: 15px;
        }

        .contacts .call_btn {
            display: none;
        }
    }
}