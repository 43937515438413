/*
    Header
*/

.header {
  position: relative;
  z-index: 99;
  background: #eaeced;

  @include tablet {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 72px;
    background: $base_bg0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .mob_btn {
    display: none;
    position: absolute;
    top: 50%;
    width: 52px;
    height: 52px;
    margin-top: -26px;
    cursor: pointer;
    font-size: 25px;
    color: $base_dark_color;
    text-decoration: none;
    text-align: center;
    line-height: 52px;

    @include mobile-m {
      width: 40px;
      font-size: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      font-family: 'social';
      z-index: 1;
    }

    &.mob_btn_menu {
      left: -10px;

      &::before {
        content: '\f0c9';
      }
    }

    &.mob_btn_phone {
      right: 0;

      &::before {
        content: '';
        background: url(../images/icon-tel.svg) no-repeat center center;
        background-size: 60% auto;
      }
    }

    &.mob_btn_cart {
      right: 52px;

      &::before {
        content: '';
        background: url(../images/icon-shopping-cart.svg) no-repeat center center;
        background-size: 60% auto;
      }

      span {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
        background: $base_red_color;
        color: $base_white_color;
        @include border-radius(100%);
        line-height: 20px;
        font-size: 13px;
        font-weight: 700;
        z-index: 2;
      }

      @include mobile-m {
        right: 40px;
      }
    }

    &.mob_btn_cab {
      right: 104px;

      &::before {
        content: '';
        background: url(../images/icon-avatar.svg) no-repeat center center;
        background-size: 55% auto;
      }

      @include mobile-m {
        display: none;
      }
    }

    &.mob_btn_search {
      right: 156px;
      display: none !important;

      &::before {
        content: '';
        background: url(../images/icon-magnifying-glass-g.svg) no-repeat center center;
        background-size: 50% auto;
      }

      @include mobile-m {
        display: none;
      }
    }

    @include tablet {
      display: block;
    }
  }

  .hrow {
    margin: 0 -15px;
    padding: 37px 0 30px 0;

    @include tablet {
      margin: 0;
      padding: 0;
      height: 72px;
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }

    .hcol {
      float: left;
      margin: 0 15px;

      @include tablet {
        margin-left: 45px;
      }
      @include mobile-m {
        margin-left: 35px;
      }
    }
  }

  .hleft {
    width: 285px;

    @include desktop {
      width: 259px;
    }
    @include tablet {
      width: 220px;
    }
    @include mobile-m {
      width: 170px;
      margin-left: 36px;
    }
  }

  .hcenter {
    width: 554px;

    @include desktop {
      width: 465px;
    }
  }

  .hright {
    width: 354px;
    text-align: right;

    @include tablet {
      display: none;
    }
  }

  .logo {
    display: block;
    font-size: 0px;
    margin: 0;

    img {
      max-width: 100%;
    }

    span {
      display: block;
      padding: 4px 0 0 0;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1em;
      color: rgba(39, 40, 49, 0.8);

      @include tablet {
        display: none;
      }
    }

    @include tablet {
      margin: 19px 0 0 0;
      span {
        padding: 0;
      }
    }
    @include mobile-m {
      margin: 25px 0 0 0;
      span {
        font-size: 10px;
      }
    }
  }

  .contacts {
    float: right;
    width: 200px;
    position: relative;
    margin: 4px 0 0 0;
    text-align: right;

    @include tablet {
      display: none;
    }

    .tel {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 22px;
      color: #1f262c;
      padding-left: 32px;

      &:hover {
        color: #1f262c;
        text-decoration: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        width: 19px;
        height: 19px;
        margin: -9.5px 0 0 0;
        background: url(../images/icon-tel.svg) no-repeat 0 0;
        background-size: contain;
      }
    }

    .call_btn {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      height: 24px;
      line-height: 22px;
      padding: 0 10px;
      border: 1px solid $base_green_color;
      text-decoration: none;
      color: $base_green_color;
      @include border-radius(3px);
      margin: яpx 0 0 0;

      &:hover {
        color: $base_white_color;
        text-decoration: none;
        background: $base_green_color;
      }
    }
  }

  .hsearch {
    position: relative;
    float: left;
    width: 320px;
    height: 35px;
    margin: 2px 0 0 0;
    z-index: 1;

    @include desktop {
      width: 245px;
    }

    &.open {
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
    }

    @include tablet {
      display: block !important;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      right: 161px;
      top: 14px;
      transform: scaleX(0);
      transform-origin: top right;
      @include transition(0.3s ease 0s);
    }

    input[type="search"] {
      width: 100%;
      height: 35px;
      background: $base_white_color;
      -webkit-appearance: none;
      border: none;
      @include border-radius(4px);
      padding: 0 40px 0 16px;
      font-family: $base_font_family;
      color: $base_grey_color;
      font-size: 13px;
      z-index: 2;
      @include ph(rgba(31, 38, 44, 0.5));
    }

    .search_btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 35px;
      cursor: pointer;
      border: none;
      background: none;
      z-index: 4;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 19px;
        height: 19px;
        margin: -9.5px 0 0 -9.5px;
        background: url(../images/icon-magnifying-glass.svg) no-repeat 0 0;
        background-size: 100% 100%;
        @include transition(0.3s ease);

        @include tablet {
          display: none;
        }
      }

      &:hover {
        &:before {
          @include transform(scale(1.1));
        }
      }
    }
  }

  .cab {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    margin: 0 0 0 16px;
    padding: 0 0 0 20px;
    text-align: right;
    color: #c1c3c4;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 1px;
      height: 100%;
      background: #d3d3d3;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 16px;
      height: 18px;
      background: url(../images/icon-avatar.svg) no-repeat 0 0;
      background-size: contain;
      margin-right: 8px;
    }

    .call_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 34px;
      line-height: 22px;
      padding: 0 10px;
      border: 1px solid $base_green_color;
      text-decoration: none;
      color: $base_white_color;
      background: $base_green_color;
      @include border-radius(3px);
      margin: 10px 0 0 0;
      transition: all 0.2s;

      &:hover {
        color: $base_white_color;
        text-decoration: none;
        background: rgba($base_green_color, 0.7);
      }
    }

    a {
      display: inline-block;
      vertical-align: top;
      color: $base_dark_color;
      text-decoration: none;
      border-bottom: 1px dotted $base_dark_color;

      &:hover {
        color: $base_dark_color;
        border-bottom-color: transparent;
        text-decoration: none;
      }

      &.sign_btn {
        max-width: 80px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.reg_btn {

      }

      &.user_btn {

      }
    }

    span {
      display: inline-block;
      vertical-align: top;
      margin: 0 3px;
    }
  }

  .time {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    margin: 0 0 0 0;
    padding: 0 0 0 20px;
    text-align: left;
    color: $base_dark_color;
    max-width: 165px;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 1px;
      height: 100%;
      background: #d3d3d3;
    }

    strong {
      font-weight: 400;
      color: $base_light_color;
    }
  }

  .h_cart {
    position: relative;
    float: right;
    width: 173px;
    height: 59px;
    padding-left: 60px;
    color: $base_white_color;
    text-decoration: none;
    background: #292f35;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 60px;
      height: 59px;
      background: $base_yellow_color;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 18px;
      left: 16px;
      width: 26px;
      height: 24px;
      background: url(../images/icon-shopping-cart.svg) no-repeat 0 0;
      background-size: 100% 100%;
      z-index: 2;
    }

    .price_value {
      display: block;
      font-size: 15px;
      color: $base_white_color;
      line-height: 59px;
      text-align: center;
    }

    .num {
      position: absolute;
      left: 48px;
      top: 19px;
      width: 24px;
      height: 24px;
      background: $base_white_color;
      text-align: center;
      line-height: 24px;
      color: $base_dark_color;
      font-size: 13px;
      @include border-radius(50%);
      z-index: 2;
    }
  }
}

/*Main Menu*/

.main_menu {
  position: relative;
  background: $base_dark_color;

  @include tablet {
    display: none;
  }

  .fw {
    & > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 173px;

      & > li {
        position: relative;
        display: block;
        width: 100%;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0px;
          width: 1px;
          height: 23px;
          margin-top: -11.5px;
          background: rgba(255, 255, 255, 0.15);
        }

        &:hover {
          & > a {
            color: $base_yellow_color;
          }

          & > ul {
            visibility: visible;
            opacity: 1;
          }
        }

        &.active {
          & > a {
            color: $base_yellow_color;
          }

          &.parent {
            & > a {
              &:after {
                background: url(../images/icon-mm-arr_h.svg) no-repeat 0 0;
              }
            }
          }
        }

        &.parent {
          & > a {
            &:after {
              content: '';
              position: relative;
              display: inline-block;
              vertical-align: middle;
              margin-left: 8px;
              width: 8px;
              height: 4px;
              background: url(../images/icon-mm-arr.svg) no-repeat 0 0;
              top: -1px;
            }

            &:hover {
              &:after {
                background: url(../images/icon-mm-arr_h.svg) no-repeat 0 0;
              }
            }
          }
        }

        &.item_sale {
          & > a {
            &:before {
              content: '';
              position: relative;
              top: -1px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
              width: 25px;
              height: 25px;
              background: $base_red_color url(../images/icon-percentage-discount.svg) no-repeat 6px 6px;
              background-size: 12px 12px;
              @include border-radius(50%);
            }
          }
        }

        &.item_cat {
          &:hover {
            & > ul {
              visibility: visible;
              opacity: 1;
            }
          }

          & > ul {
            min-width: 272px;
            background: #2d363d;
            padding: 0;

            & > li {
              display: block;
              padding: 0;
              margin: 0;
              border-bottom: 1px solid #1f262c;
              position: relative;

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                & > a {
                  color: $base_dark_color;
                  background: $base_yellow_color;

                  &:before {
                    background: $base_green_color;
                  }
                }

                & > ul {
                  visibility: visible;
                  opacity: 1;
                }
              }

              &.active {
                & > a {
                  color: $base_dark_color;
                  background: $base_yellow_color;

                  &:before {
                    background: $base_green_color;
                  }
                }

                &.parent {
                  &:after {
                    background: url(../images/icon-mm-arr_d.svg) no-repeat 0 0;
                  }
                }
              }

              &.parent {
                & > a {
                  &:after {
                    content: '';
                    position: absolute;
                    right: 13px;
                    top: 20px;
                    width: 8px;
                    height: 4px;
                    background: url(../images/icon-mm-arr.svg) no-repeat 0 0;
                    @include transform(rotate(-90deg));
                  }

                  &:hover {
                    &:after {
                      background: url(../images/icon-mm-arr_d.svg) no-repeat 0 0;
                    }
                  }
                }
              }

              & > a {
                position: relative;
                display: block;
                font-size: 15px;
                font-weight: 700;
                height: 43px;
                line-height: 43px;
                text-transform: initial;
                color: $base_white_color;
                padding: 0 25px 0 25px;
                background: #2d363d;
                text-decoration: none;
                @include transition(0.3s ease);
                white-space: nowrap;

                &:hover {
                  color: $base_dark_color;
                  background: $base_yellow_color;

                  &:before {
                    background: $base_green_color;
                  }
                }

                &::before {
                  content: '';
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width: 3px;
                  height: 100%;
                  background: transparent;
                  @include transition(0.3s ease);
                }
              }

              ul {
                position: absolute;
                top: 0px;
                left: 100%;
                min-width: 272px;
                background: $base_yellow_color;
                visibility: hidden;
                opacity: 0;
                @include transition(0.3s ease);
                padding: 0;

                @include desktop {
                  min-width: 250px;
                }

                & > li {
                  position: relative;
                  border-bottom: 1px solid #bb9b0d;

                  &:last-child {
                    border-bottom: none;
                  }

                  &:hover {
                    & > a {
                      background: $base_yellow2_color;
                    }

                    & > ul {
                      visibility: visible;
                      opacity: 1;
                    }
                  }

                  &.active {
                    & > a {
                      background: $base_yellow2_color;
                    }
                  }

                  &.parent {
                    & > a {
                      &:after {
                        content: '';
                        position: absolute;
                        right: 13px;
                        top: 20px;
                        width: 8px;
                        height: 4px;
                        background: url(../images/icon-mm-arr_d.svg) no-repeat 0 0;
                        @include transform(rotate(-90deg));
                      }
                    }
                  }

                  & > a {
                    position: relative;
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 17px;
                    height: 44px;
                    padding: 0 25px 0 25px;
                    background: $base_yellow_color;
                    color: $base_dark_color;
                    text-decoration: none;
                    @include transition(0.3s ease);
                    display: flex;
                    align-items: center;
                    width: 100%;

                    &:hover {
                      background: $base_yellow2_color;
                    }
                  }

                  & > ul {

                  }
                }
              }

              & > ul {
                & > li {
                  & > ul {
                    background: $base_yellow2_color;

                    & > li {
                      &:hover {
                        & > a {
                          background: #eaeced;
                        }
                      }

                      &.active {
                        & > a {
                          background: #eaeced;
                        }
                      }

                      & > a {
                        background: $base_yellow2_color;

                        &:hover {
                          background: #eaeced;
                        }
                      }

                      & > ul {
                        background: #eaeced;

                        & > li {
                          border-bottom-color: #adb0b3;

                          &:hover {
                            & > a {
                              background: $base_yellow2_color;
                            }
                          }

                          &.active {
                            & > a {
                              background: $base_yellow2_color;
                            }
                          }

                          & > a {
                            background: #eaeced;

                            &:hover {
                              background: $base_yellow2_color;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        & > a {
          position: relative;
          display: block;
          color: $base_white_color;
          height: 59px;
          line-height: 59px;
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          @include transition(all 0.3s ease 0s);
          text-decoration: none;
          padding: 0 30px;
          text-align: center;
          white-space: nowrap;

          &:hover {
            color: $base_yellow_color;
            text-decoration: none;
          }

          @include desktop {
            font-size: 14px;
            padding: 0 20px;
          }
        }

        & > ul {
          position: absolute;
          top: 59px;
          left: 0px;
          min-width: 164px;
          visibility: hidden;
          opacity: 0;
          @include transition(opacity 0.3s ease);
          background: #2d363d;
          border-top: 3px solid $base_yellow_color;
          padding: 20px 0;

          & > li {
            display: block;
            padding: 0 26px;
            margin: 0 0 10px 0;

            &:last-child {
              margin-bottom: 0px;
            }

            &:hover {
              & > a {
                color: $base_green_color;

                &::before {
                  color: $base_green_color;
                }
              }
            }

            &.active {
              & > a {
                color: $base_green_color;

                &::before {
                  color: $base_green_color;
                }
              }
            }

            & > a {
              position: relative;
              display: block;
              font-size: 15px;
              font-weight: 700;
              color: $base_white_color;
              padding: 0 0 0 8px;
              text-decoration: none;
              @include transition(0.3s ease);
              white-space: nowrap;

              &::before {
                content: '-';
                position: absolute;
                left: 0px;
                top: -1px;
                color: $base_white_color;
              }

              &:hover {
                color: $base_green_color;
                text-decoration: none;

                &::before {
                  color: $base_green_color;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*Quick Search*/

.twitter-typeahead {
  width: 100%;
}

.tt-menu {
  display: none;
  position: absolute;
  top: 39px;
  left: 0px;
  width: 100%;
  min-width: 370px;
  background: #fff;
  border: 1px solid #e9e9e9;
  border-top: none;
  margin: -1px 0 0 0;
  z-index: 2;

  .tt-dataset {
    padding: 0;
  }

  .search-result {
    display: block;
    border-top: 1px solid #ededed;
    padding: 10px 18px;

    &:hover {
      &.item {
        background: #f3f3f3;

        .name {
          a {
            color: $base_blue_color;
          }
        }

        .price {
          .price_new {
            color: $base_red_color;
          }
        }
      }
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    &.item {
      .image {
        position: relative;
        float: left;
        width: 40px;
        font-size: 0px;

        img {
          max-width: 100%;
        }
      }

      .info {
        min-height: 40px;
        margin: 0 0 0 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .name {
        font-size: 13px;
        font-weight: 700;
        width: 100%;
        padding-right: 20px;

        a {
          color: #737373;
          text-decoration: none;

          &:hover {
            color: #000;
          }
        }
      }

      .price {
        width: auto;
        white-space: nowrap;

        .price_old {
          font-size: 12px;
          color: #9a9a9a;
          text-decoration: line-through;
        }

        .price_new {
          color: $base_dark_color;
          font-size: 13px;

          strong {
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }

    &.all {
      padding: 14px 18px;

      a {
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: $base_link_color;
        border-bottom: 1px dotted $base_link_color;

        &:hover {
          border-bottom-color: transparent;
        }
      }
    }
  }
}