/*
    Cart
*/

.cart_page {
	 .h_title {
		 a {
			 position: relative;
			 display: inline-block;
			 top: -2px;
			 font-size: 14px;
			 font-weight: 400;
			 line-height: normal;
             color: $base_link_color;
             text-decoration: none;
			 border-bottom: 1px dotted $base_link_color;
			 text-transform: initial;
			 margin-left: 10px;
			 
			 &:hover {
				 border-color: transparent;
			 }
             
             @include mobile-m {
                 margin-left: 0px;
             }
		 }
	 }
}

.red_alert {
	 position: relative;
	 min-height: 100px;
	 padding: 24px 200px 20px 200px;
	 background: #eff1f2;
	 overflow: hidden;
     border: 1px solid $base_green_color;
     @include border-radius(3px);
	 margin: 0 0 30px 0;
    
    @include desktop {
        padding-left: 100px;
        padding-right: 100px;
    }
    @include tablet {
        padding-left: 40px;
        padding-right: 40px;
    }
    @include mobile {
        padding: 30px;
    }
    
	 .icon {
		 position: absolute;
		 left: 200px;
		 top: 50%;
         margin-top: -31px;
		 width: 62px;
		 height: 62px;
         background: $base_yellow_color;
         @include border-radius(50%);
         
		 &.price:before {
			 content: '';
			 position: absolute;
			 top: 50%;
			 left: 50%;
			 width: 28px;
             height: 28px;
             margin: -14px 0 0 -14px;
             background: url(../images/icon-cart-gift.svg) no-repeat 0 0;
             background-size: contain;
		 }
         &.info:before {
             content: '';
             position: absolute;
             top: 16px;
             left: 17px;
             width: 28px;
             height: 28px;
            background: url("../images/exclamation.svg") no-repeat 0 0;
         }
         
         @include desktop {
             left: 100px;
         }
         @include tablet {
             left: 40px;
         }
         @include mobile {
             display: none;
         }
	 }
	 .text {
		 float: left;
		 font-size: 13px;
		 padding: 0 20px 0 97px;
         
         @include tablet {
             float: none;
             padding-right: 0px;
             margin-bottom: 20px;
         }
         @include mobile {
             padding-left: 0px;
         }
         
		 .title {
			 font-size: 24px;
			 line-height: 24px;
			 margin: 0 0 7px 0;
             color: $base_dark_color;
             
             @include mobile-m {
                 font-size: 20px;
             }
             
			 strong {
				 font-weight: 700;
				 color: $base_green_color;
                 white-space: nowrap;
                 
                 @include mobile {
                     display: block;
                 }
                 @include mobile {
                     display: inline;
                 }
			 }
		 }
		 p {
			 margin: 0 0 10px 0;
             font-size: 16px;
             
             &:last-child {
                 margin: 0;
             }
             
             a {
                 text-decoration: underline;
                 &:hover {
                     text-decoration: none;
                 }
             }
		 }
	 }
	 .bts {
		 float: right;
         
         @include tablet {
             float: none;
             margin-left: 97px;
         }
         @include mobile {
             margin-left: 0px;
         }
         
         .btn {
             font-size: 16px;
             
             @include mobile-m {
                font-size: 13px;
             }
         }
	 }
}

.cart_lt {
	 float: left;
	 width: 470px;
    
    @include tablet {
        float: none;
        width: auto;
    }
    
	 .c_title {
		 position: relative;
		 font-size: 23px;
		 font-weight: 700;
		 color: $base_dark_color;
		 margin: 0 0 30px 0;
		 padding-left: 60px;
		 
         &:before {
             content: '';
             position: absolute;
             left: 0;
             top: 50%;
         }
         
         &.c_title_del {
             &:before {
                width: 42px;
                height: 30px;
                margin-top: -15px;
                background: url(../images/icon-order-del.svg) no-repeat 0 0;
                background-size: contain;
             }
         }
         &.c_title_pay {
             margin-top: 20px;
             &:before {
                width: 36px;
                height: 36px;
                margin-top: -18px;
                background: url(../images/icon-order-pay.svg) no-repeat 0 0;
                background-size: contain;
             }
         }
         &.c_title_inf {
             margin-top: 40px;
             &:before {
                width: 39px;
                height: 32px;
                margin-top: -16px;
                background: url(../images/icon-order-inf.svg) no-repeat 0 0;
                background-size: contain;
             }
         }
	 }
	 .type_items {
		 position: relative;
		 z-index: 150;
		 font-size: 0px;
		 .type_item {
			 padding: 0;
			 display: inline-block;
			 vertical-align: top;
			 width: 50%;
			 font-size: 16px;
             margin: 0 0 30px 0;
             
             @include mobile-m {
                 width: 100%;
             }
             
			 label {
				 display: block;
				 margin: 0 0 10px 0;
                 font-weight: 700;
                 white-space: nowrap;
			 }
			 .t_price {
                 line-height: 19px;
                 font-size: 13px;
                 
				 p {
					 margin: 0 0 0 28px;
					 strong {
						 font-weight: 700;
					 }
				 }
			 }
             .km_value {
                 margin-left: 28px;
                 input {
                     margin: 10px 0;
                     width: 90px;
                 }
             }
             .info_tip {
                 left: -120px;
                 &:before {
                     left: 117px;
                 }
                 
                 @include mobile {
                     left: -104px;
                     &:before {
                         left: 101px;
                     }
                 }
                 @include mobile-m {
                     left: -80px;
                     &:before {
                         left: 78px;
                     }
                 }
             }
		 }
	 }
	 .del_sel {
		 position: relative;
		 .jq-selectbox {
             width: 100%;
		 }
		 .info_btn {
			 position: absolute;
			 top: 12px;
			 left: 2px;
		 }
         .info_tip {
             left: -8px;
             &:before {
                 left: 4px;
             }
         }
		 p {
			 margin: 8px 0 0 0;
			 padding: 0;
			 font-weight: 700;
			 strong {
				 font-weight: 400;
				 color: #666;
			 }
		 }
	 }
	 .add_mess {
		 display: none;
		 margin-top: 20px;
	 }
	 .add_promo {         
		 .promo_form {
			 display: none;
			 margin-top: 20px;
             
			 input {
				 float: left;
				 width: 270px;
                 
                 @include mobile-m {
                     width: 100%;
                     float: none;
                 }
			 }
			 .bts {
				 float: right;
				 width: 187px;
                 padding-top: 0;
                 border: none;
                 
                 @include mobile-m {
                     float: none;
                     width: 100%;
                     padding-top: 15px;
                 }
                 
				 .btn {
					 display: block;
					 padding: 0 10px;
                     height: 35px;
                     line-height: 33px;
                     font-size: 14px;
                     font-weight: 400;
				 }
			 }
		 }
	 }
}

/*Forms*/

.cart_form {
	 width: 100%;
	 float: none;

	 .group {
		 margin-bottom: 20px;
		 a {
             font-size: 14px;
			 border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 border-bottom-color: transparent;
			 }
		 }
	 }
	 .label {
		 position: relative;
		 display: block;
		 margin-bottom: 8px;
		 font-size: 15px;
		 color: $base_text_color;
		 span {
			 font-weight: 400;
			 display: inline-block;
			 vertical-align: middle;
			 color: $base_red_color;
			 padding: 0 0 0 4px;
		 }
	 }
	 .value {
		 position: relative;
	 }
	 label.error {
		 color: $base_red_color;
		 font-size: 13px;
		 display: block;
		 margin-top: 5px;
	 }
	 input, textarea {
		 width: 100%;
		 height: 35px;
		 border: 1px solid #cdcecf;
		 background: none;
		 padding: 0 20px;
		 font-family: $base_font_family;
		 color: $base_text_color;
         @include border-radius(3px);
         &:focus {
             border-color: $base_green_color;
         }
		 &.error {
			 border-color: $base_red_color;
		 }
	 }
	 textarea {
		 height: 85px;
		 padding: 20px;
	 }
     .check {
         color: #7b8289;
         .text {
             display: inline-block;
             vertical-align: top;
             max-width: 85%;
         }  
     }
     .jq-selectbox__select {
         height: 45px;
         line-height: 43px;
         font-size: 15px;
         padding: 0 42px 0 21px;
     }
     .jq-selectbox__trigger {
         width: 42px;    
     }
     .jq-selectbox__trigger-arrow {
         right: 17px;    
     }
    
     .bts {
         padding-top: 20px;
         border-top: 1px solid #d7dfe7;
         
         @include mobile-m {
             text-align: center;
         }
         
         .info {
             float: left;
             max-width: 260px;
             font-size: 12px;
             color: #7b8289;
             line-height: 15px;
             
             @include mobile-m {
                 float: none;
                 max-width: 100%;
             }
             
             a {
                 color: $base_link_color;
                 border-bottom: 1px dotted $base_link_color;
                 text-decoration: none;
                 &:hover {
                     border-bottom-color: transparent;
                 }
             }
         }
         .btn {
             float: right;
             width: 187px;
             padding: 0 10px;
             display: block;
             
             @include mobile-m {
                 float: none;
                 display: inline-block;
                 margin-top: 15px;
             }
         }
     }
}

.cart_wrap {
    padding: 30px 0 0 0;
}

.cart_rt {
	 float: right;
	 width: 689px;
    
    @include desktop {
        width: 630px;
    }
    @include tablet {
        float: none;
        width: auto;
    }
    
	 &::after {
		 content: '';
		 display: block;
		 clear: both;
	 }
     
	 .order_box {
		 margin-bottom: 55px;
         &:after {
             content: '';
             display: block;
             clear: both;
         }
         
		 &.empty {
			 padding: 20px 40px;
			 background: $base_white_color;
             border: 1px solid $base_green_color;
             @include border-radius(3px);
			 font-size: 16px;
             
             @include mobile-m {
                 padding: 25px 12px;
             }
             
			 .order_title {
				 font-size: 22px;
				 font-weight: 400;
                 display: inline-block;
                 padding-left: 48px;
                 margin: 0;
                 
                 @include mobile {
                     display: block;
                     margin-bottom: 12px;
                     padding-left: 70px;
                 }
                 @include mobile-m {
                     font-size: 18px;
                 }
			 }
			 .back_btn {
				 padding: 0 0 0 30px;
				 display: inline-block;
				 text-decoration: underline;
				 &:hover {
					 text-decoration: none;
				 }
                 
                 @include mobile {
                     padding-left: 70px;
                 }
			 }
		 }
		 .order_title {
			 position: relative;
			 font-size: 23px;
			 font-weight: 700;
			 padding: 0 0 0 48px;
             color: $base_dark_color;
             margin: 0 0 30px 0;
             
			 &:before {
				 content: '';
				 position: absolute;
				 left: 0;
				 top: 50%;
				 width: 26px;
                 height: 24px;
                 margin-top: -12px;
                 background: url(../images/icon-shopping-cart.svg) no-repeat 0 0;
                 background-size: contain;
			 }
             
			 a {
                 float: right;
				 position: relative;
				 top: 7px;
				 display: inline-block;
				 vertical-align: middle;
				 font-size: 14px;
				 font-weight: 400;
				 font-family: $base_font_family;
				 color: $base_link_color;
				 text-transform: none;
				 border-bottom: 1px dotted $base_link_color;
				 margin: 0 0 0 35px;
				 line-height: normal;
				 &:hover {
					 border-bottom-color: transparent;
				 }
                 
                 @include mobile-m {
                     margin-left: 30px;
                 }
			 }
		 }
		 .cart_table {
			 height: auto;
			 overflow: visible;
			 max-height: 2000px;
		 }
		 .disc_box {
			 position: relative;
			 padding: 18px 0 18px 0;
             border-bottom: 1px solid #d7dfe7;
             
			 .add_promo {
				 float: left;
				 width: 530px;
                 
                 @include desktop {
                     width: 470px;
                 }
                 @include mobile {
                     float: right;
                 }
                 @include mobile-m {
                     float: none;
                     width: auto;
                 }
                 
				 .promo_form {
					 display: block;
					 margin: 0;
					 .group {
						 margin: 0;
						 float: left;
						 width: 347px;
                         
                         @include desktop {
                             width: 285px;
                         }
                         @include mobile-m {
                             width: 170px;
                         }
					 }
					 .bts {
						 float: right;
						 width: 170px;
                         
                         @include mobile-m {
                             width: 120px;
                             .btn {
                                 span {
                                     display: none;
                                 }
                             }
                         }
                         
						 .btn {
							 padding: 0 10px;
							 display: block;
                             height: 35px;
                             line-height: 33px;
                             font-size: 14px;
                             font-weight: 400;
						 }
					 }
				 }
			 }
			 .discount {
				 float: right;
				 line-height: 35px;
				 width: 148px;
				 font-size: 18px;
				 color: $base_red_color;
                 text-align: right;
                 
                 @include mobile {
                     text-align: right;
                     padding-top: 15px;
                 }
                 
				 .dis_value {
					 font-weight: 700;
				 }
			 }
		 }
		 .total_box {
			 float: right;
             max-width: 80%;
			 margin-top: 20px;
             
             @include desktop {
                 width: 60%;
             }
             @include mobile {
                 width: 100%;
             }
             
			 ul {
				 li {
					 margin: 0 0 7px 0;
					 clear: both;
					 font-size: 18px;
					 line-height: 27px;
                     
                     @include mobile-m {
                         font-size: 14px;
                     }
                     
					 .price_value {
						 float: right;
						 width: 140px;
						 margin: 0 0 0 25px;
						 text-align: left;
                         
                         @include mobile-m {
                             width: 130px;
                             margin-left: 25px;
                         }
                         
						 strong {
							 font-weight: 400;
						 }
					 }
					 &:last-child {
						 margin: 16px 0 0 0;
                         
						 .price_value {
							 font-size: 28px;
                             font-weight: 400;
                             
                             @include mobile-m {
                                 font-size: 20px;
                             }
                             
                             strong {
                                 font-weight: 700;
                             }
						 }
					 }
				 }
			 }
		 }
         .back_link {
             padding: 12px 0 0 0;
             
             @include mobile {
                 display: none;
             }
         }
	 }
}

/* Cart Items */

.cart_table {
	 position: relative;
	 max-height: 490px;
	 overflow: auto;
     border: 1px solid #d7dfe7;
     @include border-radius(3px);
    
	 table {
		 width: 100%;
         margin: 0;
         padding: 0;
         border: none;
		 border-collapse: collapse;
         
         @include mobile-m {
             display: block;
         }
         
         tr {
             &:first-child {
                 td {
                     border-top: none;
                 }
             }
             @include mobile-m {
                 position: relative;
                 display: block;
             }
         }
         
		 td {
			 vertical-align: top;
			 text-align: left;
			 padding: 30px 0 30px 20px;
             border-top: 1px solid #d7dfe7;
             
             @include mobile {
                 padding-left: 10px;
             }
             @include mobile-m {
                 display: block;
             }
             
			 &.item-col {
				 width: 55%;
                 
                 @include mobile-m {
                    width: 100%;
                    padding-left: 0px;
                    padding-right: 10px;
                    padding-bottom: 0px;
                 }
			 }
			 &.count-col {
				 width: 20%;
                 
                 @include mobile-m {
                     width: 181px;
                     padding-left: 90px;
                     display: inline-block;
                     vertical-align: top;
                     border-top: none;
                     padding-top: 15px;
                 }
			 }
			 &.price-col {
				 width: 20%;
                 
                 @include mobile-m {
                     display: inline-block;
                     vertical-align: top;
                     width: auto;
                     padding-top: 15px;
                     padding-left: 15px;
                     border-top: none;
                 }
			 }
			 &.gift-col {
			 	 vertical-align: middle;
			 	 text-align: center;
			 }
             &.remove-col {
                 min-width: 23px;
                 max-width: 23px;
                 width: 23px;
                 padding-top: 0px;
                 padding-left: 0px;
                 text-align: right;
                 
                 @include mobile-m {
                     padding: 0;
                     border-top: none;
                     width: 0px;
                     min-width: 0px;
                     max-width: 0px;
                 }
             }
			 .image {
				position: relative;
				float: left;
				width: 152px;
				font-size: 0px;
				overflow: hidden;
				img {
				    max-width: 100%;
				}
                 
                @include desktop {
                    width: 150px;
                }
                @include mobile {
                    width: 74px;
                }
			 }
			 .desc {
				 margin-left: 175px;
                 
                 @include desktop {
                     margin-left: 180px;
                 }
                 @include mobile {
                     margin-left: 90px;
                 }
             }
             .sku {
                 font-size: 13px;
                 color: rgba(31,38,44,0.7);
                 margin: 0 0 7px 0;
             }
             .name {
                display: block;
                color: $base_dark_color;
                font-size: 13px;
                font-weight: 700;
                line-height: 17px;
                margin: 0 0 15px 0;
                a {
                    color: $base_dark_color;
                    text-decoration: none;
                    &:hover {
                         text-decoration: underline;
                    }
                 }
            }      
            .price2 {
                 font-size: 13px;
                 white-space: normal;
                 strong {
                     font-weight: 700;
                     white-space: nowrap;
                 }
            }
            .title {
                 color: rgba(31,38,44,0.7);
                 font-size: 13px;
				 margin-bottom: 7px;
            }
			 .count {
				 position: relative;
				 width: 91px;
                 
                 .jq-number__field {
                     height: 34px;
                     
                     input {
                         height: 34px;
                         font-size: 14px;
                         padding: 0 26px;
                     }
                 }
                 .jq-number__spin {
                     width: 26px;
                     &:after {
                         height: 20px;
                         margin-top: -10px;
                     }
                 }
			 }
			 .price {
				 font-size: 19px;
                 font-weight: 400;
				 white-space: nowrap;
                 
                 @include mobile {
                     font-size: 13px;
                 }
                 
				 strong {
					 font-weight: 700;
                     
                     @include mobile {
                        font-size: 18px;
                     }
				 }
			 }
			 .close_btn {
				 position: relative;
				 cursor: pointer;
                 display: inline-block;
                 vertical-align: top;
				 width: 23px;
				 height: 23px;
				 background: #eff1f2;
                 @include border-radius(3px);
                 border-left: 1px solid #d7dfe7;
                 border-bottom: 1px solid #d7dfe7;
				 @include transition(0.3s ease);
                 
                 @include mobile-m {
                     position: absolute;
                     right: 0px;
                     top: 1px;
                 }
                 
				 &:before, &:after {
					 content: '';
					 position: absolute;
					 top: 50%;
					 left: 50%;
					 margin: -4px 0 0 -4px;
                     width: 8px;
                     height: 8px;
                     background: url(../images/icon-info-close.svg) no-repeat 0 0;
                     @include transition(0.3s ease);
				 }
                 &:after {
                     opacity: 0;
                     background: url(../images/icon-info-close2.svg) no-repeat 0 0;
                 }
				 &:hover {
					 background: $base_green_color;
					 &:before {
						 opacity: 0;
					 }
                     &:after {
						 opacity: 1;
					 }
				 }
			 }
			 .gift_txt {
				 font-size: 30px;
				 font-weight: 700;
			 }
		 }
	 }
}

/*Info Tip*/

.info_btn {
	 position: relative;
	 display: inline-block;
	 vertical-align: middle;
	 margin-left: 6px;
	 cursor: pointer;
	 top: -1px;
     position: relative;
	 cursor: pointer;
	 width: 16px;
     height: 16px;
     background: url(../images/icon-info-btn.svg) no-repeat 0 0;
     
	 &::before {
		 content: '';
		 position: absolute;
		 top: 0px;
		 left: 0px;
		 opacity: 0;
		 width: 16px;
         height: 16px;
         background: url(../images/icon-info-btn.svg) no-repeat 0 0;
		 @include transition(0.5s ease);
	 }
	 &:hover, &.active {
		 &::before {
			 opacity: 1;
		 }
	 }
}

.info_tip {
	 display: none;
	 position: absolute;
	 top: 30px;
	 left: -20px;
	 width: 220px;
	 padding: 20px 15px;
	 background: $base_white_color;
	 color: $base_text_color;
	 border: 1px solid $base_green_color;
	 font-size: 12px;
	 text-transform: initial;
	 font-weight: 400;
	 z-index: 101;
	 text-align: left;
	 @include border-radius(3px);
     white-space: normal;
    
	 &::before {
		 content: '';
		 position: absolute;
		 left: 20px;
		 top: -8px;
		 width: 18px;
         height: 10px;
         background: url(../images/info_arr.png) no-repeat 0 0;
	 }
	 &::after {
		 content: '';
		 position: fixed;
		 top: 0px;
		 bottom: 0px;
		 left: 0px;
		 right: 0px;
	 }
	 strong {
		 display: block;
		 margin: 0 0 2px 0;
		 font-weight: 700;
		 text-transform: uppercase;
	 }
	 p {
		 margin: 0;
		 padding: 0;
	 }
	 a {
		 color: $base_link_color;
		 text-decoration: underline;
		 border-bottom: none!important;
		 &:hover {
			 text-decoration: none;
		 }
	 }
	 .close_tip {
		 position: absolute;
		 top: -1px;
		 right: -1px;
		 width: 24px;
         height: 24px;
         background: #eff1f2;
         @include border-radius(3px);
         border: 1px solid #d7dfe7;
         
         &:before {
             content: '';
             position: absolute;
             top: 50%;
             left: 50%;
             margin: -3.5px 0 0 -3.5px;
             width: 8px;
             height: 8px;
             margin: -4px 0 0 -4px;
             background: url(../images/icon-info-close.svg) no-repeat 0 0;
             background-size: contain;
         }
		 &:hover {
			 
		 }
	 }
}