/*
    Home Page
*/

/*Main Slider*/

.m_slider {
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 4px;
        background: rgba(255,255,255,0.5);
        z-index: 2;
    }
    
    .item {
        position: relative;
        height: 373px;
    }
    
    .slide {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        z-index: 1;

        /*&:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(18,21,24,0.3);
            z-index: 2;
        }*/
    }
    
    .fw {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    .info {
        position: relative;
        max-width: 380px;
        
        .title {
            font-size: 39px;
            font-weight: 700;
            line-height: 43px;
            text-transform: uppercase;
            margin: 0 0 10px 0;
            color: $base_white_color;
            
            @include mobile-m {
                font-size: 24px;
                line-height: 32px;
            }
        }
        
        .desc {
            font-size: 17px;
            max-width: 332px;
            color: $base_white_color;
            padding: 20px;
            background: rgba(31,38,44,0.5);
            margin: 0 0 20px 0;
            
            li {
                position: relative;
                margin:  0 0 6px 0;
                padding: 0 0 0 28px;
                &:before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0px;
                    width: 13px;
                    height: 13px;
                    background: url(../images/icon-check2.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        
        .bts {
            .btn {
				padding: 0 15px;
                height: 36px;
                line-height: 32px;
                @include border-radius(2px);
                background: transparent;
                border: 2px solid $base_yellow_color;
                color: $base_yellow_color;
                font-size: 14px;
                letter-spacing: 0px;
                text-transform: initial;
                font-size: 14px;
                &:after {
                    content: '';
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    width: 14px;
                    height: 9px;
                    background: url(../images/btn_arr_1.svg) no-repeat 0 0;
                    background-size: contain;
                    margin-left: 4px;
                    @include transition(0.3s ease);
                }
                &:hover {
                    color: $base_yellow_color;
                    background: $base_dark_color;
                    border-color: $base_dark_color;
                    &:after {
                        @include transform(translateX(3px));
                    }
                }
            }
        }
    }
    
    .circle-item {
        position: absolute;
        @include border-radius(50%);
        overflow: hidden;
        font-size: 0px;
        
        @include mobile {
            display: none;
        }
        
        &:hover {
            a {
                &:before {
                    opacity: 0.3;
                }
                img {
                    @include transform(scale(1.1));
                }
            }
        }
        
        a {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            @include border-radius(50%);
            overflow: hidden;
            
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: #000;
                opacity: 0;
                @include border-radius(50%);
                @include transition(0.3s ease);
                z-index: 2;
            }
        }
        img {
            @include transition(0.3s ease);
            width: 100%;
            min-height: 100%;
        }
        
        &-b {
            width: 323px;
            height: 323px;
            bottom: 25px;
            right: 5px;
            border: 12px solid rgba(255,255,255,0.7);
            z-index: 2;
            
            @include tablet {
                width: 223px;
                height: 223px;
                border-width: 6px;
            }
        }
        &-s {
            width: 173px;
            height: 173px;
            bottom: 54px;
            right: 292px;
            border: 6px solid rgba(255,255,255,1);
            z-index: 3;
            
            @include tablet {
                width: 73px;
                height: 73px;
                right: 192px;
                border-width: 3px;
            }
        }
    }
    
    .owl-nav {
        display: none !important;
    }
    
    .owl-dots {
        bottom: 15px;
        text-align: center;
        
        .owl-dot {
            border-color: #eaeaea;
            &.active {
                background: #eaeaea;
            }
        }
    }
}

/*Main Benefits*/

.m_benefits {
    padding: 45px 0;
    background: $base_bg0;
    
    @media (min-width: 1200px){
        .wow.fadeInUp {
            visibility: hidden;
        }
    }
    
    .benefit_items {
        font-size: 0px;
    }
    .benefit_item {
        display: inline-block;
        vertical-align: top;
        width: 16.66%;
        text-align: center;
        border-right: 1px solid #d7dfe7;
        margin: 0 0 12px 0;
        padding: 7px 0 0 0;
        min-height: 208px;
        
        @include tablet {
            width: 33.3%;
        }
        @include mobile-m {
            width: 50%;
        }
        
        &:last-child {
            border-right: none;
            
            @include tablet {
                border-right: 1px solid #d7dfe7;
            }
        }
        
        &:hover {
            .image {
                background: $base_green_color;
                @include transform(translate3d(0,-10px,0));
            }
            .icon {
                &:before {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        .image {
            position: relative;
            margin: 0 auto 12px auto;
            width: 102px;
            height: 102px;
            @include border-radius(50%);
            border: 2px solid $base_green_color;
            background: $base_white_color;
            @include transition(0.5s ease);
        }
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transition(0.5s ease);
            
            &:before, &:after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                @include transition(0.3s ease);
            }
            &:after {
                opacity: 0;
            }
            &.icon-1 {
                width: 44px;
                height: 40px;
                margin: -20px 0 0 -22px;
                &:before {
                    background: url(../images/icon_ben_1.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_1h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-2 {
                width: 49px;
                height: 49px;
                margin: -24.5px 0 0 -24.5px;
                &:before {
                    background: url(../images/icon_ben_2.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_2h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-3 {
                width: 41px;
                height: 41px;
                margin: -20.5px 0 0 -20.5px;
                &:before {
                    background: url(../images/icon_ben_3.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_3h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-4 {
                width: 44px;
                height: 28px;
                margin: -14px 0 0 -22px;
                
                &:before {
                    background: url(../images/icon_ben_4.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_4h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-5 {
                width: 44px;
                height: 43px;
                margin: -21.5px 0 0 -22px;
                
                &:before {
                    background: url(../images/icon_ben_5.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_5h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
            &.icon-6 {
                width: 42px;
                height: 35px;
                margin: -17.5px 0 0 -21px;
                
                &:before {
                    background: url(../images/icon_ben_6.svg) no-repeat 0 0;
                    background-size: contain;
                }
                &:after {
                    background: url(../images/icon_ben_6h.svg) no-repeat 0 0;
                    background-size: contain;
                }
            }
        }
        .name {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            @include transition(0.3s ease);
            color: $base_dark_color;
            padding: 0 10px;
            
            @include mobile {
                font-size: 12px;
            }
        }
    }
}

/*Main Prods*/

.m_prods {
    padding: 22px 0 0;
    
}

.prods_menu {
	 margin: 0 0 32px 0;
	 text-align: center;
     
     ul {
         position: relative;
         display: inline-block;
         vertical-align: top;
         padding: 0 44px;
         &:before, &:after {
             content: '';
             position: absolute;
             top: 50%;
             width: 44px;
             height: 1px;
             background: $base_border_color;
         }
         &:before {
             left: 0px;
         }
         &:after {
             right: 0px;
         }
     }
     li {
         display: inline-block;
         vertical-align: top;
         margin: 0 5px;
     }
	 a {
		 display: block;
		 padding: 0 24px;
		 font-size: 16px;
         font-weight: 700;
         height: 37px;
         line-height: 37px;
		 color: $base_dark_color;
		 text-decoration: none;
         @include border-radius(4px);
         @include transition(0.3s ease);
         
		 &:hover {
			 color: $base_light_color;
		 }
		 &.active {
             color: $base_white_color;
             background: $base_dark_color;
		 }
	 }
}

.prods_tabs {
    min-height: 428px;
    
    @include tablet {
        min-height: 0px;
    }
}

.prods_tab {
	 display: none;
	 &.active {
		 display: block;
	 }
}

.prods_carousel {
	 .owl-carousel {
         .item {
             padding: 10px 0 62px;
         }
	 }
     .owl-stage-outer {
         margin-left: -10px;
         margin-right: -10px;
         padding: 0 10px;
     }
     .owl-prev, .owl-next {
        margin-top: -76px;   
     }
}

/* Main About */

.m_about {
    padding: 50px 0 34px;
    
    .lt {
        float: left;
        width: 790px;
        
        @include desktop {
            width: 740px;
        }
        @include tablet {
            width: auto;
            float: none;
            margin-bottom: 50px;
        }
    }
    .rt {
        float: right;
        width: 375px;
        
        @include tablet {
            width: auto;
            float: none;
        }
        
        .title {
            float: left;
            font-size: 26px;
            line-height: 26px;
            font-weight: 700;
            margin: 0 0 35px 0;
        }
        .bts {
            float: right;
        }
    }
    
    .blog_items {
        margin: 0;
        .blog_item {
            display: block;
            width: auto;
            margin: 0 0 35px 0;
        }
    }
    
    .blog_item {
        .image {
            width: 90px;
        }
        .info {
            margin-left: 116px;
        }
    }
}

/* Main Form */

.m_formbg {
    .m_form {
        position: relative;
        min-height: 135px;
        background: $base_dark_color;
        padding: 37px 74px 20px 78px;
        
        @include tablet {
            padding-left: 30px;
            padding-right: 30px;
        }
        @include mobile-m {
            padding: 20px;
        }
        
        &:before, &:after {
            content: '';
            position: absolute;
            z-index: 1;
        }
        &:before {
            width: 96px;
            height: 70px;
            top: 0px;
            left: 0px;
            background: url(../images/mform_i_1.png) no-repeat 0 0;
        }
        &:after {
            width: 199px;
            height: 117px;
            right: 0px;
            top: 0px;
            background: url(../images/mform_i_2.png) no-repeat 0 0;
            
            @include tablet {
                display: none;
            }
        }
    }
    .lt, .rt {
        position: relative;
        z-index: 2;
    }
    .lt {
        float: left;
        width: 294px;
        
        @include tablet {
            float: none;
            width: auto;
        }
    }
    .rt {
        float: right;
        width: 775px;
        
        @include desktop {
            width: 690px;
        }
        @include tablet {
            float: none;
            width: auto;
        }
    }
    .title {
        position: relative;
        padding: 0 0 0 96px;
        font-size: 18px;
        line-height: 20px;
        color: $base_white_color;
        
        @include tablet {
            margin-bottom: 30px;
        }
        @include mobile-m {
            padding: 0;
            &:before, &:after {
                display: none;
            }
        }
        
        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 68px;
            height: 68px;
            border: 1px solid $base_yellow_color;
            @include border-radius(50%);
            
            @include tablet {
                top: -7px;
            }
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 18px;
            left: 20px;
            width: 28px;
            height: 28px;
            background: url(../images/icon-draw.svg) no-repeat 0 0;
            background-size: contain;
            
            @include tablet {
                top: 11px;
            }
        }
        
        strong {
            display: block;
            font-size: 25px;
            line-height: 25px;
            font-weight: 400;
            text-transform: uppercase;
            color: $base_yellow_color;
            margin: 0 0 8px 0;
        }
    }
    .field {
        float: left;
        width: 294px;
        margin-right: 10px;
        
        @include desktop {
            width: 250px;
        }
        @include tablet {
            width: 235px;
        }
        @include mobile {
            width: auto;
            float: none;
            margin: 0 0 15px 0;
        }
        
        input {
            width: 100%;
            height: 40px;
            padding: 0 20px;
            background: #ededed;
            border: none;
            @include border-radius(3px);
            font-size: 16px;
            font-family: $base_font_family;
            @include ph(rgba(38,30,26,0.7));
        }
    }
    .bts {
        float: left;
        width: 166px;
        .btn {
            display: block;
            padding: 0 10px;
        }
    }
    .info {
        clear: both;
        display: block;
        font-size: 12px;
        color: #b8b9ba;
        padding: 18px 0 0 0;
        a {
            color: #b8b9ba;
            border-bottom: 1px dotted #b8b9ba;
            text-decoration: none;
            &:hover {
                color: #b8b9ba;
                border-bottom-color: transparent;
                text-decoration: none;
            }
        }
    }
}

/* Main Brands */

.m_brands {
    padding: 50px 0 70px 0;
    
    .owl-carousel {
        @include mobile {
            padding: 0 40px;
        }
    }
    
    .item {
        height: 72px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        a {
            /*
            &:hover {
                img {
                    filter: none;
                    -webkit-filter: none;
                    -moz-filter: none;
                    -ms-filter: none;
                    -o-filter: none;
                }
            }
            img {
                max-width: 90%;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter: grayscale(100%);
                //filter: url(grayscale.svg#grayscale);
                filter: gray;
            }
            */
            img {
                max-width: 90%;
            }
        }
    }
}

/* Main Steps */

.m_steps {
    padding: 40px 0;
    background: $base_bg0;
    min-height: 400px;
    
    @include mobile {
        display: none;
    }
    
    @media (min-width: 1200px){
        .wow.fadeInUp {
            visibility: hidden;
        }
    }
    
    .steps_items {
        margin: 0 -13px;
        font-size: 0px;
        
        @include tablet {
            text-align: center;
        }
    }
    .steps_item {
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
        position: relative;
        width: 229px;
        min-height: 254px;
        background: $base_white_color;
        margin: 0 13px 30px;
        padding: 18px;
        text-align: center;
        @include transition(0.5s ease);
        
        @include desktop {
            width: 206px;
        }
        @include tablet {
            width: 220px;
        }
        @include mobile {
            width: 226px;
        }
        
        &:hover {
            @include transform(translate3d(0, -10px, 0));
            .circle {
                background: $base_dark_color;
            }
        }
        
        .num {
            position: absolute;
            top: 18px;
            left: 18px;
            width: 30px;
            height: 30px;
            border: 2px solid $base_yellow_color;
            @include border-radius(50%);
            background: $base_white_color;
            font-size: 14px;
            color: $base_dark_color;
            line-height: 26px;
            text-align: center;
        }
        
        .circle {
            position: relative;
            width: 98px;
            height: 98px;
            background: $base_green_color;
            @include border-radius(50%);
            @include transition(0.5s ease);
            margin: 0 auto 32px auto;
        }
        
        .name {
            margin: 0 0 12px 0;
            font-size: 17px;
            font-weight: 700;
            color: $base_dark_color;
            text-transform: uppercase;
        }
        
        .desc {
            font-size: 15px;
            line-height: 18px;
        }
        
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
            
            &.icon-1 {
                width: 42px;
                height: 42px;
                margin: -21px 0 0 -21px;
                &:before {
                    background: url(../images/icon_step_1.svg) no-repeat 0 0;
                }
            }
            
            &.icon-2 {
                width: 43px;
                height: 42px;
                margin: -21px 0 0 -21.5px;
                &:before {
                    background: url(../images/icon_step_2.svg) no-repeat 0 0;
                }
            }
            
            &.icon-3 {
                width: 41px;
                height: 44px;
                margin: -22px 0 0 -20.5px;
                &:before {
                    background: url(../images/icon_step_3.svg) no-repeat 0 0;
                }
            }
            
            &.icon-4 {
                width: 44px;
                height: 32px;
                margin: -16px 0 0 -22px;
                &:before {
                    background: url(../images/icon_step_4.svg) no-repeat 0 0;
                }
            }
            
            &.icon-5 {
                width: 43px;
                height: 43px;
                margin: -21.5px 0 0 -21.5px;
                &:before {
                    background: url(../images/icon_step_5.svg) no-repeat 0 0;
                }
            }
        }
    }
}