/*
    Thanks
*/

.thanks_top {
	 position: relative;
     padding-right: 50%;
     padding-top: 20px; 
     margin-bottom: 70px;
    
     @include tablet {
         padding-right: 30%;
     }
     @include mobile {
         padding-right: 0px;
     }
    
     &:before {
		 content: '';
		 position: absolute;
		 right: 140px;
		 top: 35px;
		 width: 292px;
		 height: 292px;
		 background: url(../images/icon-cart2.svg) no-repeat 0 0;
         background-size: contain;
         
         @include tablet {
             right: 0px;
             width: 192px;
             height: 192px;
         }
         @include mobile {
             display: none;
         }
	 }
     
	 .desc {
		 position: relative;
		 
         .title {
             font-size: 30px;
             font-weight: 400;
             text-transform: initial;
             margin: 0 0 20px 0;
         }
         
		 p {
             line-height: 24px;
             font-size: 16px;
			 margin: 0 0 20px 0;
             
             @include mobile {
                 font-size: 15px;
             }
		 }
         
		 .tel {
			 position: relative;
			 margin: 0 0 20px 0;
			 padding-left: 34px;
			 font-size: 16px;
             line-height: 27px;
             
             strong {
                 display: block;
                 font-weight: 700;
                 margin: 0 0 4px 0;
             }
             
			 &:before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                width: 19px;
                height: 19px;
                background: url(../images/icon-phone.svg) no-repeat 0 0;
                background-size: contain;
             }
		 }
	 }
}

.thanks_bottom {
	 border: 1px solid #d3d3d3;
     @include border-radius(3px);
     padding: 25px;
     
	 .title {
		 font-size: 28px;
		 font-weight: 400;
		 margin: 0 0 20px 0;
         text-align: center;
         span {
             font-size: 16px;
             display: block;
             padding: 4px 0 0 0;
         }
	 }
     
	 .widgets_items {
		 text-align: center;
         
		 .widgets_item {
			 display: inline-block;
			 vertical-align: top;
			 margin: 0 15px 15px;
			 
			 img {
                 @include mobile {
                    max-width: 100%;
                 }
			 }
		 }
	 }
}