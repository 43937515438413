/*FAQ*/

.faq_subtitle {
	 position: relative;
	 font-size: 18px;
	 line-height: 18px;
	 font-family: $base_font2_family;
	 font-weight: 700;
	 color: $base_text_color;
	 margin: 0 0 10px 0;
	 padding: 0 0 0 25px;
	 text-transform: uppercase;
	 &::before {
		 content: '';
		 position: absolute;
		 top: 1px;
		 left: 0px;
		 @extend .sprite-icons;
		 @include stitches-sprite(-20px, -3955px, 15px, 16px);
	 }
}

.faq_items {
	 padding: 0 0 35px 25px;
	 
	 h2 {
		 margin: 0 0 14px 0;
	 }
	 .faq_tabs {
		 margin-bottom: 25px;
	 }
}

.faq_item {
	 margin-top: 14px;
	 .name {
		 .name_btn {
			 position: relative;
			 margin-right: 8px;
			 text-decoration: none;
			 span {
				 color: $base_text_color;
				 font-size: 18px;
				 border-bottom: 1px dotted $base_text_color;
			 }
			 &:hover {
				 span {
					 color: $base_link_color;
					 border-bottom-color: transparent;
				 }
			 }
		 }
		 .link_btn {
			 display: inline-block;
			 vertical-align: top;
			 margin-top: 5px;
			 background: url(../images/link.png) no-repeat;
			 width: 12px;
			 height: 12px;
		 }
	 }
	 &.active {
		 .name {
			 .name_btn {
				 span {
					 color: $base_link_color;
					 border-bottom: none;
				 }
			 }
		 }
		 .link_btn {
			 @include stitches-sprite(-20px, -2389px, 12px, 12px);
		 }
	 }
	 .faq_content {
		 display: none;
		 background: $base_bg0;
		 padding: 15px 25px 10px 25px;
		 margin: 10px 0 0 -25px;
	 }
}

.c_form.faq_form {
	 padding: 35px 48px 60px 48px;
	 background: $base_bg0;
	 max-width: 780px;
	 margin-top: 10px;
	 form {
		 max-width: 520px;
	 }
	 .quest_form {
		 display: none;
		 margin-top: 20px;
		 &.open {
			 display: block;
		 }
		 .form-submit .bts{
			 margin-top: 15px;
		 }
	 }
	 .col_lt {
		 float: left;
		 width: 240px;
		 margin-right: 25px;
		 margin-bottom: 35px;
		 .bts {
			 text-align: left;
			 float: none;
			 .btn {
				 width: 100%;
			 }
		 }
	 }
	 .col_rt {
		 float: left;
		 width: 200px;
		 .tel {
			 strong {
				 display: block;
				 font-weight: 700;
				 font-size: 18px;
				 margin-bottom: 10px;
			 }
		 }
		 .call_btn {
			 text-decoration: none;
			 border-bottom: 1px dotted $base_link_color;
			 &:hover {
				 border-bottom: 1px dotted transparent;
			 }
		 }
	 }
	 .bts {
		 .btn {
			 float: right;
			 min-width: 240px;
			 max-width: 100%;
		 }
	 }
}